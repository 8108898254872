import React, { useContext, useState, useEffect } from 'react'
import {
    useLazyGetOrganisationSettingsQuery,
    usePutCardInfoMutation
} from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store'
import BusyArea from '../../../Shared/BusyArea'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import Title from '../../../Shared/Title'
import 'react-device-frameset/styles/marvel-devices.min.css'
import LoadingPage from "../../LoadingPage";
import Button from "../../../Shared/Form/Button";
import { ModalContext } from "../../../Shared/Modal/Modal";
import { Hydra } from "../../../../Utils/Hydra";
import { useTranslation } from "react-i18next";
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import MobileApp from "../../../Shared/App/MobileApp";
import MyCard from "../../../Shared/App/MyCard";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Form from '../../../Shared/Form/Form'

const AppBuilderpage = () => {
    const org_id = useAppSelector((s) => s.organisation.id)!
    const theme = useAppSelector((s) => s.organisation.settings?.theme)
    const [getSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery()
    const [putCardInfo] = usePutCardInfoMutation()
    const [error, setError] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [busy, setBusy] = useState(false)
    const [fileBlob, setFileBlob] = useState('')
    const modalContext = useContext(ModalContext)
    const { t } = useTranslation()

    const [name, setName] = useState('');
    const [displayMode, setDisplayMode] = useState('')
    const [cardInfo1, setCardInfo1] = useState('');
    const [cardInfo2, setCardInfo2] = useState('');
    const [cardInfo3, setCardInfo3] = useState('');
    const [cardInfo4, setCardInfo4] = useState('');

    useEffect(() => {
        if (org_id) {
            getSettings(org_id)
        }
    }, [org_id])

    useEffect(() => {
        if (settings) {
            setName(settings.name)
            settings.photo ? setDisplayMode(settings.displayMode) : setDisplayMode('qrOnly')
            setCardInfo1(settings.cardInfo[0])
            setCardInfo2(settings.cardInfo[1])
            setCardInfo3(settings.cardInfo[2])
            setCardInfo4(settings.cardInfo[3])
        }
    }, [settings])

    useEffect(() => {
        if (!theme) return

        setFileBlob(theme.icon)
    }, [theme])

    const saveSettings = async () => {
        if (busy) return;

        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            const outData = [
                cardInfo1,
                cardInfo2,
                cardInfo3,
                cardInfo4
            ]

            await putCardInfo({
                id: org_id,
                cardInfo: outData,
                name: name,
                displayMode: displayMode
            }).unwrap()
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
        }
        setBusy(false);
    }

    const handleNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setName(e.target.value)
    }

    const handleDisplayModeChange = (e: { target: { value: React.SetStateAction<string> } }) => {
        setDisplayMode(e.target.value)
    }

    const handleValueChange = (e: any) => {
        if (e.target.id == 0) {
            setCardInfo1(e.target.value)
        } else if (e.target.id == 1) {
            setCardInfo2(e.target.value)
        } else if (e.target.id == 2) {
            setCardInfo3(e.target.value)
        } else if (e.target.id == 3) {
            setCardInfo4(e.target.value)
        }
    }

    if (!settings) return <LoadingPage />

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col xl:flex-row'>
                    <div className='w-full'>
                        <Title text='Digipas.app' subtitle={t('cards:app:title') as string} textNotBold />
                        <StatusPopups type='error' text={error} />
                        <StatusPopups type='success' text={successMessage} />

                        <Form submit={saveSettings}>
                            <h1 className='mb-1'>{t('cards:app:welcomeSettings')}</h1>
                            <div className='mb-6'>
                                <FormControl sx={{ width: '100%' }} size="small">
                                    <Select
                                        native
                                        defaultValue={settings.name}
                                        onChange={handleNameChange}
                                    >
                                        <option className='font-sans' value="name">{t('cards:app:fullname')}</option>
                                        <option className='font-sans' value="firstname">{t('cards:list:initials')}</option>
                                        <option className='font-sans' value="lastname">{t('cards:list:lastname')}</option>
                                        <option className='font-sans' value="membershipId">{t('cards:list:membershipId')}</option>
                                    </Select>
                                </FormControl>
                            </div>

                            {settings.photo && (
                                <>
                                    <h1 className='mb-1'>{t('cards:app:displaySettings')}</h1>
                                    <div className='mb-6'>
                                        <FormControl sx={{ width: '100%' }} size="small">
                                            <Select
                                                native
                                                defaultValue={settings.displayMode}
                                                onChange={handleDisplayModeChange}
                                            >
                                                <option className='font-sans' value='qrOnly'>{t('cards:app:qrOnly')}</option>
                                                <option className='font-sans' value='qrAndPhoto'>{t('cards:app:qrAndPhoto')}</option>
                                                <option className='font-sans' value='photoOnly'>{t('cards:app:photoOnly')}</option>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </>
                            )}

                            <h1 className='mb-1'>{t('cards:app:cardInfo')}</h1>
                            <div className='flex gap-2 mb-4 grid grid-cols-2'>
                                {settings.cardInfo.map((value, index) =>
                                    <FormControl sx={{ width: '100%' }} key={index} size="small">
                                        <Select

                                            native
                                            defaultValue={value}
                                            onChange={handleValueChange}
                                            id={index.toString()}
                                        >
                                            <optgroup className='font-sans not-italic' label={t('cards:list:memberInformation') || ''}>
                                                {settings?.familyCards ? (<option className='font-sans' value="numberOfPeople">{t('cards:edit:numberOfPeople')}</option>) : null}
                                                <option className='font-sans' value="types">{t('cards:list:type')}</option>
                                                <option className='font-sans' value="membershipId">{t('cards:list:membershipId')}</option>
                                                <option className='font-sans' value="team">{t('cards:list:team')}</option>

                                            </optgroup>
                                            <optgroup className='font-sans not-italic' label={t('cards:list:registration') || ''}>
                                                <option className='font-sans' value="activeFrom">{t('cards:list:activeFrom')}</option>
                                                <option className='font-sans' value="activeUntil">{t('cards:list:activeUntil')}</option>

                                            </optgroup>
                                            <optgroup className='font-sans not-italic' label={t('cards:list:personalDetails') || ''}>
                                                <option className='font-sans' value="email">{t('cards:list:email')}</option>
                                                <option className='font-sans' value="dateOfBirth">{t('cards:list:dateOfBirth')}</option>
                                                <option className='font-sans' value="telephoneNumber">{t('cards:list:phone')}</option>
                                                <option className='font-sans' value="name">{t('cards:app:fullname')}</option>
                                            </optgroup>

                                            <optgroup className='font-sans not-italic' label={t('cards:list:other') || ''}>
                                                <option className='font-sans' value="department">{t('cards:list:department')}</option>
                                                <option className='font-sans' value="">{t('cards:app:empty')}</option>
                                                <option className='font-sans' value="location">{t('cards:list:location')}</option>
                                                <option className='font-sans' value="discount">{t('cards:list:discount')}</option>
                                            </optgroup>
                                        </Select>
                                    </FormControl>
                                )}
                            </div>
                        </Form>

                        <Button
                            icon={faFloppyDisk} iconright
                            title={t('cards:app:save')}
                            action={modalContext.withModal({ title: t('modal:title'), body: t('modal:changes') }, saveSettings)}
                        />
                    </div>


                    <div className='-m-32 sm:m-0 -m-20 sm:mt-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 overflow-hidden flex justify-center xl:justify-end mb-0 sm:mb-10'>
                        <MobileApp
                            activeItem="mycard"
                        >
                            <MyCard
                                customName={name}
                                customDisplayMode={displayMode}
                                customCardInfo1={cardInfo1 === '' ? 'empty' : cardInfo1}
                                customCardInfo2={cardInfo2 === '' ? 'empty' : cardInfo2}
                                customCardInfo3={cardInfo3 === '' ? 'empty' : cardInfo3}
                                customCardInfo4={cardInfo4 === '' ? 'empty' : cardInfo4}
                            />
                        </MobileApp>
                    </div>
                </div>
            </BusyArea>
        </>
    )
}



export default AppBuilderpage
