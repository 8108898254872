import Title from "../../../Shared/Title";

import StatusPopups from "../../../Shared/Form/StatusPopups";
import React, { useEffect, useState } from "react";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import { all, max, min } from "../../../../Utils/InValidation";
import Input from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import { useAppSelector } from "../../../../Redux/store";
import { Hydra } from "../../../../Utils/Hydra";
import { faCardsBlank, faPlus } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../../Shared/Table/Table";
import Column from "../../../Shared/Table/Column";
import Header from "../../../Shared/Table/Header";
import Row from "../../../Shared/Table/Row";
import moment from "moment";
import Data from "../../../Shared/Table/Data";
import { useLazyGetSponsorDataQuery } from "../../../../Redux/Api/Organisations";
import LoadingPage from "../../LoadingPage";
import { useNavigate } from "react-router-dom";
import { useDeleteSponsorMutation } from "../../../../Redux/Api/Sponsors";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { motion, AnimatePresence } from "framer-motion";

const SponsorListPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const org_id = useAppSelector((s) => s.organisation.id)!;

    const [uploadFile] = useUploadMutation();
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [successAddMessage, setSuccessAddMessage] = useState('');
    const [fileBlob, setFileBlob] = useState('');
    const [selectedIcon, setSelectedIcon] = useState<string | null>(null);

    const [getSponsorData, { data }] = useLazyGetSponsorDataQuery();
    const [deleteSponsor] = useDeleteSponsorMutation();

    const isMediumScreen = useMediaQuery('(min-width: 1125px)');
    const isSmallScreen = useMediaQuery('(min-width: 475px)');

    useEffect(() => {
        if (sessionStorage.getItem('successMessage')) {
            setSuccessAddMessage(sessionStorage.getItem('successMessage')!);
            sessionStorage.removeItem('successMessage');
        }
    }, [org_id]);

    useEffect(() => {
        if (org_id)
            getSponsorData({
                id: org_id
            });
    }, [org_id, getSponsorData]);

    const deleteSponsorAction = async (id: string) => {
        if (busy) return;

        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            await deleteSponsor(id);
            if (org_id)
                getSponsorData({
                    id: org_id
                });
            setSuccessMessage(t('sponsor:sponsorDeleted') as string);
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
        }
        setBusy(false);
    };

    const showIcon = (icon: string) => {
        setSelectedIcon(icon);
    }

    const closeIconPreview = () => {
        setSelectedIcon(null);
    }

    if (!data) return <LoadingPage />;

    return (
        <>
            <Title text={t('sponsor:title')} />
            <StatusPopups type='error' text={error} />
            <StatusPopups type='success' text={successMessage} />
            <StatusPopups type='success' text={successAddMessage} />

            <BusyArea busy={busy}>
                <Table
                    subheader={(
                        <div className="mb-2 flex gap-4">
                            <Button
                                action={() => navigate(`/${i18n.language}/dashboard/sponsor/create`)}
                                className="my-6"
                                title={t('sponsor:add')}
                                icon={faPlus}
                                iconright
                            />
                        </div>
                    )}
                    withSearch
                    withDelete
                >
                    <Header>
                        <Column name={t('sponsor:hiddenName')} />
                        <Column hidden={!isMediumScreen} name={t('sponsor:name')} />
                        <Column hidden={!isMediumScreen} name={t('sponsor:logo')} />
                        <Column hidden={!isSmallScreen} name={t('sponsor:activeFrom')} />
                        <Column hidden={!isMediumScreen} name={t('sponsor:activeUntil')} />
                    </Header>
                    <Data>
                        {data.map(({ id, hiddenName, name, icon, from, until }, i) => (
                            <Row
                                firstButton={'action'}
                                lastButton={'deleteAction'}
                                key={id}
                                index={i}
                                indexKey={id}
                                editUrl={`/${i18n.language}/dashboard/sponsor/edit/` + id}
                                deleteAction={() => deleteSponsorAction(id)}
                                deleteConfirmMessage={t('modal:delete') as string}
                                action={() => showIcon(icon)}
                                data={
                                    isMediumScreen
                                        ? [
                                            hiddenName && hiddenName.trim() !== '' ? hiddenName : "-",
                                            name && name.trim() !== '' ? name : "-",
                                            icon,
                                            moment(from).format('DD-MM-YYYY'),
                                            moment(until).format('DD-MM-YYYY')
                                        ]
                                        : isSmallScreen ? [
                                            hiddenName && hiddenName.trim() !== '' ? hiddenName : "-",
                                            moment(from).format('DD-MM-YYYY')
                                        ] : [
                                            hiddenName && hiddenName.trim() !== '' ? hiddenName : "-"
                                        ]
                                }
                            />
                        ))}
                    </Data>
                </Table>
            </BusyArea>

            <AnimatePresence>
                {selectedIcon && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0}} onClick={closeIconPreview} className="fixed top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center z-[1000]">
                        <FontAwesomeIcon icon={faXmark} className="absolute top-4 right-4 text-4xl text-white cursor-pointer" />
                        <motion.img initial={{scale: 0.5}} animate={{scale: 1}} exit={{scale: 1.2}} src={selectedIcon} alt="Sponsor Logo Preview" className="max-h-[calc(100vh-100px)] max-w-[calc(100vw-100px)]" />
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default SponsorListPage;
