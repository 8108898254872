import {Hydra} from '../../Utils/Hydra';
import Api from "./Api";
import {Type, TypesResponse} from './Request/Types';
import {RegiserUserRequest, UserResponse, UsersResponse} from './Request/User';

const typeApi = Api.injectEndpoints({
    endpoints: build => ({
        getType: build.query<TypesResponse, void>({
            query: () => (`/api/types`),
            transformResponse: Hydra.MultiResponseTransformer
        }),

        postType: build.mutation<Hydra.Result<string>, Type>({
            query: ({name, organisation}) => ({
                url: `/api/types`,
                method: 'POST',
                body: JSON.stringify({
                    name,
                    organisation: organisation
                })
            })
        }),

        deleteType: build.mutation<Hydra.Result<string>, string>({
            query: (id) => ({
                url: `/api/types/${id}`,
                method: 'DELETE'
            })
        }),
    }),
});

export const {
    useGetTypeQuery,
    useLazyGetTypeQuery,
    usePostTypeMutation,
    useDeleteTypeMutation,
} = typeApi;
export default typeApi;