import React, { ReactNode } from "react";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../Redux/store";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useTranslation } from "react-i18next";
import { setDataGridSelection } from "../../Redux/Slices/Datagrid";

export type DataGridToolbarAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type DataGridToolbarProps = {
    actions?: Array<DataGridToolbarAction>,
    style?: React.CSSProperties,
    select?: any
    selectAll?: any,
    onSelectAll?: () => void,
    data?: any
};

export default function DataGridToolbar({ actions, style, data, selectAll, onSelectAll, select }: DataGridToolbarProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            {actions && actions.length > 0 && (
                <div className="py-2 flex items-center">
                    <Button className={`${open ? 'bg-gray-300' : 'bg-white'} hover:bg-blue-500 text-black hover:text-white py-2 rounded-[0.4rem] overflow-hidden`} disabled={select === 0} onClick={handleClick} size="small" variant="contained"
                    sx={{ ml: 1, mr: 2,
                        ...(open && {
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                bottom: 0,
                                left: '50%',
                                transform: 'translateX(-50%) translateY(50%) rotate(45deg)',
                                width: 10,
                                height: 10,
                                bgcolor: 'white',
                                border: '1px solid #D7E3F2',
                            },
                        }),
                     }}>
                        <KeyboardArrowDownOutlinedIcon sx={{ mr: 1 }} className={`${open && ('rotate-180')}`}/> {t('datagrid:actions')}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                borderRadius: '0.4rem',
                                border: '1px solid #D7E3F2',
                            },
                        }}
                    >
                {actions.map(action => (
                    <MenuItem key={action.label} onClick={() => action.onClick()} className="h-8 px-5 my-1">
                        <div className="flex items-center w-full border-b-[1px] border-[#dbdbdb]">
                        {action.icon && (
                            <ListItemIcon sx={{ minWidth: 32 }}>
                                {action.icon}
                            </ListItemIcon>
                        )}
                        <ListItemText className="py-1 px-1">
                            {action.label}
                        </ListItemText>
                        </div>
                    </MenuItem>
                ))}
                    </Menu>

                    {selectAll && (
                        <button
                            style={{ overflow: 'hidden', border: '1px solid #D7E3F2', fontFamily: 'Inter' }}
                            className='bg-white rounded-[0.4rem] hover:bg-blue-500 text-black hover:text-white py-2 px-3'
                            onClick={onSelectAll}
                        >
                            {select >= data ? t('datagrid:deselectAll') : t('datagrid:selectAll')}
                        </button>
                    )}
                </div>
            )}
        </GridToolbarContainer>
    );
}
