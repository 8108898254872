import { motion } from 'framer-motion'
import React from 'react'

export interface LoaderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    heightClass?: string;
}

const Loader = (props: LoaderProps) => {
    return (
        <div {...props} className={`flex py-10 ${ props.className || 'text-brand' }`}>
            <div className='m-auto'>
                <svg className={ props.heightClass || 'w-20 h-20' } xmlns="http://www.w3.org/2000/svg" transform="scale(1.5)" viewBox="0 0 23.509 23.838">
                    <g>
                        <motion.g animate={{
                            scale: [1, 0.9, 1],
                        }} transition={{ duration: 1.5, repeat: Infinity }}>
                            <path d="M0,0V5.811H2.22V2.22H5.594V0Z" fill="currentColor"/>
                            <path d="M41.819,0V2.22h3.591V5.593h2.22V0Z" transform="translate(-24.12)" fill="currentColor"/>
                            <path d="M45.706,42.6v3.591H42.333v2.22h5.593V42.6Z" transform="translate(-24.417 -24.568)" fill="currentColor"/>
                            <path d="M0,43.108V48.7H5.812v-2.22H2.22V43.108Z" transform="translate(0 -24.864)" fill="currentColor"/>
                        </motion.g>
                        <motion.rect 
                            style={{
                                translateX: 5.959
                            }}
                            animate={{
                                translateY: [6.772, 6.772 + 2, 6.772, 6.772, 6.772, 6.772]
                            }}
                            transition={{ repeat: Infinity, duration: 1.5 }}
                            width="2.22" height="10.951" fill="currentColor"/>
                        <motion.rect 
                            style={{
                                translateX: 10.78
                            }}
                            animate={{
                                translateY: [6.772, 6.772 + 2, 6.772, 6.772, 6.772, 6.772]
                            }}
                            transition={{ repeat: Infinity, delay: 0.3, duration: 1.5 }}
                            width="2.22" height="10.951" fill="currentColor"/>
                        <motion.rect 
                            style={{
                                translateX: 15.601
                            }}
                            animate={{
                                translateY: [6.772, 6.772 + 2, 6.772, 6.772, 6.772, 6.772]
                            }}
                            transition={{ repeat: Infinity, delay: 0.6, duration: 1.5 }}
                            width="2.22" height="10.951" fill="currentColor"/>
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default Loader