import React from 'react'
import Loader from '../Shared/Loader'

interface LoadingPageProps {
    className?: string
}

const LoadingPage = ({className}: LoadingPageProps) => {
    return (
        <div className={`flex flex-grow items-center h-full justify-center ${className}`}>
            <Loader/>
        </div>
    )
}

export default LoadingPage
