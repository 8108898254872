import Title from "../../../Shared/Title";
import StatusPopups from "../../../Shared/Form/StatusPopups";
import React, { useState } from "react";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import { usePostSponsorMutation } from "../../../../Redux/Api/Sponsors";
import { useAddSponsorLogoMutation } from "../../../../Redux/Api/Sponsors";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import { all, max, min } from "../../../../Utils/InValidation";
import Input, { FailedRequirements } from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import { useAppSelector } from "../../../../Redux/store";
import { Hydra } from "../../../../Utils/Hydra";
import { SponsorResponse } from "../../../../Redux/Api/Request/Sponsors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import SponsorPreview from "./SponsorPreview";

const SponsorAddPage = () => {

    const org_id = useAppSelector((s) => s.organisation.id)!;
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [uploadFile] = useUploadMutation();
    const [postSponsor] = usePostSponsorMutation();
    const [addSponsorLogo] = useAddSponsorLogoMutation();

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fileBlob, setFileBlob] = useState('');

    const [sponsorName, setSponsorName] = useState('');
    const [sponsorImage, setSponsorImage] = useState('');

    const toMYSQLDate = (datestring: string) => {
        const date = new Date(datestring)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }

    const submit = async (data: {
        hiddenName: string,
        name: string,
        logo: File,
        activeFrom: string,
        activeUntil: string,

    }) => {
        if (data.activeUntil === null || data.activeUntil === '') {
            data.activeUntil = '9999-01-01';
        }

        if (FailedRequirements(data, 'logo', 'activeFrom', 'activeUntil')) return setError(t("sponsor:error") || "")
        if (busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        try {
            const upload_id = await uploadFile({
                file: data.logo
            }).unwrap();


            const outData = {
                hiddenName: data.hiddenName,
                name: data.name,
                icon: '',
                from: toMYSQLDate(data.activeFrom),
                until: toMYSQLDate(data.activeUntil),
                organisation: org_id
            }

            const sponsor_id = await postSponsor([org_id, outData]).unwrap();

            const logo_url = await addSponsorLogo({
                sponsor_id: sponsor_id.toString(),
                upload_id
            });

            sessionStorage.setItem('successMessage', t('sponsor:sponsorAdded') as string);
            setBusy(false)
            navigate(`/${i18n.language}/dashboard/sponsor/list`);
        } catch (e) {
            let err = e as Hydra.Error;
            setError(err['hydra:description']);
            setBusy(false);
        }
    }

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col xl:flex-row'>
                    <div className='w-full'>
                        <Title text={t('sponsor:title')} subtitle={t('sponsor:subtitle') as string} />
                        <StatusPopups type='error' text={error} />
                        <StatusPopups type='success' text={successMessage} />
                        <Form submit={submit}>
                            <Input
                                invalidator={all(min(0), max(255))}
                                sublabel={t('cards:list:optional') as string}
                                label={t('sponsor:hiddenName')}
                                id='hiddenName' />
                            <Input
                                onChange={(e) => setSponsorName((e.target as HTMLInputElement).value)}
                                invalidator={all(min(0), max(255))}
                                sublabel={t('cards:list:optional') as string}
                                initial=" "
                                label={t('sponsor:name')}
                                id='name' />
                            <FilePicker required submitted={error ? true : false} id='logo' label={t('sponsor:logo')} onChangeBlob={(blob) => { setFileBlob(blob); setSponsorImage(blob); }} accept={['image/*']} />
                            <CalendarInput
                                required
                                submitted={error ? true : false}
                                label={t('sponsor:activeFrom')}
                                id='activeFrom' />
                            <CalendarInput
                                sublabel={t('cards:list:optional') as string}
                                label={t('sponsor:activeUntil')}
                                id='activeUntil' />
                            <div className='flex mt-5'>
                                <Button icon={faPlus} iconright title={t('sponsor:add')} />
                            </div>
                        </Form>
                    </div>
                    <div className='-m-32 sm:m-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 overflow-hidden flex justify-center xl:justify-end mb-0 sm:mb-10'>
                        <SponsorPreview title={sponsorName} image={sponsorImage} />
                    </div>
                </div>
            </BusyArea>
        </>
    )
}

export default SponsorAddPage
