import {
    useCheckOutCardsMutation,
    useLazyGetCheckedInScansTodayQuery,
    useLazyGetCheckedOutScansTodayQuery,
    useLazyGetScansQuery,
}
    from '../../../../Redux/Api/Organisations';
import Title from '../../../Shared/Title'
import Card from '../../../Shared/Card/Card'
import CardGrid from '../../../Shared/Card/CardGrid'
import CardValue from '../../../Shared/Card/CardValue'
import HourGraph from './HourGraph'
import ReleaseNotes from './ReleaseNotes'
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons'
import { useAppSelector } from '../../../../Redux/store'
import React, { useContext, useEffect, useState } from 'react'
import { JWT } from '../../../../Utils/JWT'
import Button from "../../../Shared/Form/Button"
import { Hydra } from "../../../../Utils/Hydra"
import StatusPopups from "../../../Shared/Form/StatusPopups"
import BusyArea from '../../../Shared/BusyArea'
import moment from "moment"
import { ModalContext } from "../../../Shared/Modal/Modal"
import { useTranslation } from 'react-i18next'
import GraphFilter from "./graphfilter"
import UserScan from "./UserScan"
import { useLocation, useNavigate } from 'react-router-dom'
import NotificationHelper from "../../../../Helper/NotificationHelper";
import { faUserMinus, faUserPlus, faUserCheck, faUserTag, faGifts, faGift, faTreeChristmas, faCashRegister, faChampagneGlass, faEmptySet, faUsersSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../Shared/Tooltip';
const Overview = () => {

    const [getScans, { data: scans }] = useLazyGetScansQuery();
    const [getCheckedInScansToday, { data: checkedInScansToday }] = useLazyGetCheckedInScansTodayQuery();
    const [getCheckedOutScansToday, { data: checkedOutScansToday }] = useLazyGetCheckedOutScansTodayQuery();
    const org_id = useAppSelector((s) => s.organisation.id);
    const [checkOutCards] = useCheckOutCardsMutation();

    const isSocialUser = JWT.read<Array<string>>('roles')?.includes('ROLE_SOCIAL');

    const modalContext = useContext(ModalContext)
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const first_name = JWT.read<string>('given_name')
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hoveredButtons, setHoveredButtons] = useState<{ [key: string]: boolean }>({});

    const [checkIns, setCheckIns] = useState(0)
    const [checkOuts, setCheckOuts] = useState(0)
    const [checkedIns, setCheckedIns] = useState(0)
    const [uniekcheckIns, setUniekCheckIns] = useState(0)
    const [grafiek, setGrafiek] = useState(false);


    const [checkedInstoday, setCheckInsToday] = useState(0)
    const [checkOutsToday, setCheckOutsToday] = useState(0)

    const [selectedDates, setSelectedDates] = useState({
        toDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        fromDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),

    });

    const today = new Date();
    const isWinter = today.getMonth() === 11 || (today.getMonth() === 0 && today.getDate() <= 5);
    const isNewYear = today.getMonth() === 0 && today.getDate() <= 5;

    let [checkGraph, setCheckGraph] = useState(new Array(3).fill(new Array(15).fill(0)))

    useEffect(() => {
        setBusy(true)
        if (org_id && selectedDates.fromDate && selectedDates.toDate) {
            const loadData = async () => {
                const toDate = new Date(selectedDates.toDate);
                const fromDate = new Date(selectedDates.fromDate);

                toDate.setDate(toDate.getDate() + 1);

                getScans({
                    org_id,
                    fromDate: formatDateToYMD(fromDate, 2),
                    toDate: formatDateToYMD(toDate, 2),
                });
            }
            loadData();

        }
        setBusy(false)
    }, [selectedDates, org_id, getScans, grafiek]);

    const handleDatesChange = (dates: { fromDate: Date; toDate: Date }) => {
        setSelectedDates(dates);
    };

    const formatDateToYMD = (date: Date, number: number): string => {
        date.setHours(number)
        return date.toISOString().slice(0, 10);
    };

    const calculateTotal = (arrOrNumber: number) => {
        if (Array.isArray(arrOrNumber)) {
            const trimmedArray = arrOrNumber.slice(0, -1);
            return trimmedArray.reduce((acc, curr) => acc + curr, 0);
        } else {
            return arrOrNumber;
        }
    };

    useEffect(() => {
        if (!org_id) return;

        getCheckedInScansToday(org_id);
        getCheckedOutScansToday(org_id);


    }, [org_id]);

    useEffect(() => {
        if (checkedInstoday !== undefined && checkOutsToday !== undefined) {
            if (checkedInstoday >= checkOutsToday) {
                setCheckedIns(checkedInstoday - checkOutsToday);
            }
        }
    }, [checkedInstoday, checkOutsToday]);

    useEffect(() => {
        if (checkedInScansToday !== undefined && checkedOutScansToday !== undefined) {
            // Update state with correct values
            setCheckInsToday(checkedInScansToday);
            setCheckOutsToday(checkedOutScansToday);
        }
    }, [checkedInScansToday, checkedOutScansToday]);


    useEffect(() => {
        if (scans && scans.length > 0) {
            const [checkedins, checkins, checkouts, uniekcheckins] = scans;
            if (calculateTotal(checkins) != 0 || calculateTotal(checkouts) != 0) {
                setGrafiek(true);
                setCheckGraph(scans);
            } else {
                setGrafiek(false);
                setCheckGraph(scans);
            }

            if (checkedOutScansToday != null && checkedInScansToday != null) {
                if (checkedInScansToday >= checkedOutScansToday) {
                    setCheckedIns(checkedInScansToday - checkedOutScansToday);
                }
            }
            setCheckIns(calculateTotal(checkins));
            setCheckOuts(calculateTotal(checkouts));
            setUniekCheckIns(calculateTotal(uniekcheckins));
        }
    }, [scans, checkedOutScansToday, checkedInScansToday]);

    const handleClick = (check: string): React.MouseEventHandler<HTMLDivElement> => (event) => {
        if (check !== 'checkedin') {
            navigate(`/${i18n.language}/dashboard/cards/list`, { state: { todate: formatDateToYMD(selectedDates.toDate, 2), fromdate: formatDateToYMD(selectedDates.fromDate, 2), check: check } });
        } else {
            navigate(`/${i18n.language}/dashboard/cards/list`, { state: { todate: formatDateToYMD(new Date, 2), fromdate: formatDateToYMD(new Date, 2), check: 'checkedin' } });
        }
    };

    const checkOut = async () => {
        if (busy) return;

        setBusy(true);

        try {
            if (org_id) {
                await checkOutCards(org_id);
            }
        } catch (e) {
            let err = e as Hydra.Error;
            NotificationHelper.showError(err['hydra:description']);
        }
        setBusy(false);
    }

    const formatDateToDMoth = (date: Date, number: number): string => {
        const defaultLanguage = 'nl';
        const language = i18n.language || defaultLanguage;
        const currentYear = new Date().getFullYear();
        const startDate = selectedDates.fromDate;
        const endDate = selectedDates.toDate;

        date.setHours(number);
        if (startDate.getFullYear() !== currentYear || endDate.getFullYear() !== currentYear) {
            return date.toLocaleDateString(`${language}-BE`, { day: 'numeric', month: 'long', year: 'numeric' });
        }
        return date.toLocaleDateString(`${language}-BE`, { day: 'numeric', month: 'long' });
    };

    return (
        <>
            {!isSocialUser && <ReleaseNotes />}

            <StatusPopups type='error' text={error} />
            <StatusPopups type='success' text={successMessage} />
            <BusyArea busy={busy}>
                <div className='flex flex-wrap justify-between'>
                    <Title hasWave textNotBold subtitle={t('overview:subtitle') as string} text={t('overview:welcome') + ` ${first_name}`} className='z-[2]' />
                    {!isSocialUser &&
                        <div>
                            <Button
                                title={t('overview:checkout')}
                                icon={faCashRegister}
                                action={modalContext.withModal({ title: t('modal:title'), body: t('modal:body') }, checkOut)}
                                className='mb-2 z-[2]'
                            />
                        </div>
                    }
                </div>

                <GraphFilter icon={faCalendarDays} onDatesChange={handleDatesChange} />

                <CardGrid cols={4} style={{ position: 'relative', alignItems: 'stretch', justifyItems: 'stretch' }}>

                    {isWinter && (
                        <>
                            <FontAwesomeIcon icon={faTreeChristmas} className='z-[1] absolute text-[225px] -top-[200px] left-[60px] text-gray-400/25' />
                            <FontAwesomeIcon icon={faGifts} className='z-[1] absolute text-[75px] -top-[70px] left-3 text-gray-400' />
                            {!isNewYear && <FontAwesomeIcon icon={faGift} className='z-[1] absolute text-[75px] -top-[70px] left-[120px] text-gray-400' />}
                        </>
                    )}

                    {isNewYear && (
                        <>
                            <FontAwesomeIcon icon={faChampagneGlass} className='z-[1] absolute text-[80px] -top-[85px] left-[150px] text-gray-400 rotate-12' />
                            <FontAwesomeIcon icon={faChampagneGlass} className='z-[1] absolute text-[80px] -top-[85px] left-[200px] text-gray-400 -rotate-12' />
                        </>
                    )}

                    <Card
                        className='group z-[2]'
                        title={t('overview:currentCheckin')}
                        onClick={!isSocialUser ? handleClick('checkedin') : undefined}
                        icon={faUserCheck}
                        hideOverflow
                        rounded>
                        <CardValue net='positive' value={checkedIns} />
                        {!isSocialUser &&
                            <a className={`text-end text-black p-1 px-3`}>
                                <span className={`group-hover:underline`}>
                                    {t('overview:lookAt')}
                                </span>
                            </a>
                        }
                    </Card>

                    <Card
                        className='group z-[2]'
                        title={t('overview:uniqueUser')}
                        onClick={!isSocialUser ? handleClick('checkin') : undefined}
                        icon={faUserTag}
                        hideOverflow
                        rounded>
                        <CardValue net='positive' value={uniekcheckIns} />
                        {!isSocialUser &&
                            <a className={`text-end text-black p-1 px-3`}>
                                <span className={`group-hover:underline`}>
                                    {t('overview:lookAt')}
                                </span>
                            </a>
                        }
                    </Card>

                    <Card
                        className='group z-[2]'
                        title={t('overview:checkinUser')}
                        onClick={!isSocialUser ? handleClick('checkin') : undefined}
                        icon={faUserPlus}
                        hideOverflow
                        rounded>
                        <CardValue net='positive' value={checkIns} />
                        {!isSocialUser &&
                            <a className={`text-end text-black p-1 px-3`}>
                                <span className={`group-hover:underline`}>
                                    {t('overview:lookAt')}
                                </span>
                            </a>
                        }
                    </Card>

                    <Card
                        className='group z-[2]'
                        title={t('overview:checkoutUser')}
                        onClick={!isSocialUser ? handleClick('checkout') : undefined}
                        icon={faUserMinus}
                        hideOverflow
                        rounded>
                        <CardValue net='negative' value={checkOuts} />
                        {!isSocialUser &&
                            <a className={`text-end text-black p-1 px-3`}>
                                <span className={`group-hover:underline`}>
                                    {t('overview:lookAt')}
                                </span>
                            </a>
                        }
                    </Card>

                </CardGrid>
                {grafiek ? (
                    <div className="flex space-y-5 mt-4 flex-col xl:flex-row xl:space-x-6 xl:space-y-0 mb-10">
                        <div className="w-full">
                            <HourGraph data={checkGraph} todate={selectedDates.toDate} fromdate={selectedDates.fromDate} />
                        </div>
                        <div className="w-full">
                            <UserScan todate={selectedDates.toDate} fromdate={selectedDates.fromDate} />
                        </div>
                    </div>
                ) : (
                    <div className="relative z-[2] border-[1px] border-[#D7E3F2] rounded-[0.4rem] bg-white">
                        <div className='border-b-[1px] border-[#D7E3F2] flex justify-between items-center bg-black/[1%] cursor-default'>
                            <h1 className='text-[16px] flex items-center gap-2 font-bold px-5 p-4'>
                                {formatDateToDMoth(selectedDates.fromDate, 0) === formatDateToDMoth(selectedDates.toDate, 23)
                                    ? t('overview:noScansInOneDay', { date: formatDateToDMoth(selectedDates.fromDate, 0) })
                                    : t('overview:noScans', { fromdate: formatDateToDMoth(selectedDates.fromDate, 0), todate: formatDateToDMoth(selectedDates.toDate, 23) })
                                }
                                <Tooltip imgSmall text={t('overview:noDataText')} />
                            </h1>

                            <FontAwesomeIcon className='mr-5 text-xl opacity-[25%]' icon={faUsersSlash} />
                        </div>
                        <div className='py-16 text-center font-bold text-xl'>
                            <h1>{t('datagrid:noData')}</h1>
                        </div>
                    </div>
                )}
            </BusyArea >
        </>
    )
}

export default Overview
