import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../Shared/Form/Button';
import Form from '../../Shared/Form/Form';
import Input from '../../Shared/Form/Input';
import Title from '../../Shared/Title';
import SidePanelWrapper from './../SidePanelWrapper';
import { Strings } from "../../../Strings/nl";
import DigipasLogoWidePNG from "../../../Assets/digipaslogowide.png";
import { useForgotPasswordMutation } from "../../../Redux/Api/Auth";
import { useAppSelector } from "../../../Redux/store";
import { Hydra } from "../../../Utils/Hydra";
import StatusPopups from "../../Shared/Form/StatusPopups";
import BusyArea from "../../Shared/BusyArea";
import LanguageDropdown from '../../Shared/LanguageDropdown';
import { useTranslation } from "react-i18next";
import IconSVG from '../../../Assets/icon.svg?component';

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const logoutError = useAppSelector((s) => s.auth.logoutReason);

    const [forgotPassword, setForgotPassword] = useForgotPasswordMutation();
    const [error, setError] = useState<string | undefined>(logoutError);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const pathSegments = window.location.pathname.split('/');
        const currentLang = pathSegments[1].toLowerCase();
        const validLanguages = ['nl', 'en', 'de', 'fr', 'es'];

        if (validLanguages.includes(currentLang)) {
            i18n.changeLanguage(currentLang).then(() => {
            });
        }
    }, [i18n]);

    const submit = async (data: { email: string; language: string }) => {
        if (busy) return;

        setBusy(true);
        setSuccessMessage('');
        setError('');

        const requestData = {
            email: data.email,
            language: i18n.language
        };

        try {
            await forgotPassword(requestData).unwrap();
            setSuccessMessage(t("forgotPassword:mailSend") + data.email);
        } catch (e) {
            setError(t("forgotPassword:error") || "");
        }
        setBusy(false);
    };

    return (
        <>
            <SidePanelWrapper style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh', backgroundColor: 'white' }}>
                <StatusPopups type='success' text={successMessage} alignLeft />
                <StatusPopups type='error' text={error === "noRightsToThisAction" ? undefined : error} alignLeft />
                <Title noHyphens text={t("forgotPassword:forgotPassword")} subtitle={t("forgotPassword:forgotPasswordPrefix") || ""} />
                <BusyArea busy={busy}>
                    <div className='flex justify-between items-center absolute top-0 left-0 p-5 w-full'>
                        <div className='text-brand flex items-center gap-2 font-bold'><IconSVG className='w-10 h-10' /><span className='text-2xl'>Digipas</span></div>
                        <LanguageDropdown />
                    </div>
                    <Form submit={submit}>
                        <Input submitted={error ? true : false} label={t("forgotPassword:email")} id='email' />
                        <div className='flex gap-4 justify-between flex-wrap'>
                            <Button title={t("forgotPassword:reset")} />
                            <Button
                                icon={faArrowLeft}
                                nosubmit
                                action={() => navigate(`/${i18n.language}/login`)}
                                title={t("forgotPassword:back")}
                            />
                        </div>
                    </Form>
                </BusyArea>
            </SidePanelWrapper>
        </>
    );
};

export default ForgotPasswordPage;
