import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import Title from '../../../Shared/Title'
import { useTranslation } from "react-i18next";
import React, { act } from 'react';
import Button from '../../../Shared/Form/Button';
import { ModalProps } from './types';

const AlertModal: React.FC<ModalProps> = ({ isOpen, onClose, action, actionTitle, alertTitle, alertMessage }) => {

  const [closeAction] = useState<(value: boolean) => void>(() => void 0)
  const { t } = useTranslation()

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className='fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-[110]'>
        <motion.div
          initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
          animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
          exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
          className='rounded-[0.4rem] flex flex-col z-[110] w-[50%] bg-light-200 '>
          <div className='flex flex-col m-20'>
            <Title text={alertTitle} />
            <div className='mt-4'>
              {alertMessage}
            </div>
            <div className='flex gap-4 mt-8 justify-between'>
              <Button title={t ('cards:list:close')} action={onClose} />
              {action && actionTitle && <Button title={actionTitle} action={action}/>}
            </div>
          </div>
        </motion.div>
        <motion.div
          key="background-div"
          onClick={() => closeAction(false)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.3 }}
          exit={{ opacity: 0 }}
          className='fixed left-0 top-0 w-screen h-screen bg-black' />
      </div>
    </AnimatePresence>
  )
}

export default AlertModal
