import { IconDefinition, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { randomBytes } from 'crypto';
import React, { useRef, useState, useEffect } from 'react'

export interface ButtonProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    title: string,
    action?: (arg?: any) => void;
    nosubmit?: boolean;
    icon?: IconDefinition;
    iconright?: boolean;
    secondary?: boolean;
    disabled?: boolean;
    notRelative?: boolean;
}

const Button = (props: ButtonProps) => {
    const { title, action, className, secondary, disabled, nosubmit, iconright, icon, notRelative, ...restProps } = props;

    const spinning = icon === faSpinner;

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [sizeClass, setSizeClass] = useState('snow-button');

    const today = new Date();
    const isWinter = (today.getMonth() === 11) ||
        (today.getMonth() === 0 && today.getDate() <= 5);

    useEffect(() => {
        if (buttonRef.current && isWinter) {
            const { offsetWidth } = buttonRef.current;

            const random = Math.random() < 0.5;

            if (offsetWidth < 130) {
                setSizeClass(random ? 'snow-button-1' : 'snow-button-3');
            } else {
                setSizeClass(offsetWidth < 150 ? random ? 'snow-button-2': 'snow-button-5' : offsetWidth < 250 ? 'snow-button' : 'snow-button-4');
            }
        }
    }, []);

    return (
        <button
            ref={buttonRef}
            type={nosubmit ? 'button' : 'submit'}
            {...restProps}
            className={`${!notRelative && 'relative'} ${disabled && 'pointer-events-none opacity-40'} rounded-[0.4rem] ${sizeClass} flex cursor-pointer transition-colors px-6 py-2 ${
                secondary ? 'hover:bg-light-100 border-2 text-gray-700 border-accent-light' : 'text-white bg-accent hover:bg-accent-light'
            } justify-center gap-4 items-center ${className}`}
            onClick={props.action}
        >
            {icon && !iconright && <FontAwesomeIcon className={`${spinning ? 'animate-spin' : ''}`} icon={icon} />}
            {title}
            {icon && iconright && <FontAwesomeIcon className={`${spinning ? 'animate-spin' : ''}`} icon={icon} />}
        </button>
    );
};

export default Button;
