import React, {PropsWithChildren} from "react";
import AppAccordion from "../AppAccordion";
import {useTranslation} from "react-i18next";

type FilterProps = PropsWithChildren<{}>;

export default function Filter({children}: FilterProps) {
    const { t } = useTranslation()
    return (
        <div style={{marginBottom: 22, fontFamily: 'Inter'}}>
            <AppAccordion title={t('datagrid:filter')} defaultExpanded={true} >
                {children}
            </AppAccordion>
        </div>
    );
}
