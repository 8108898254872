import { useState, useEffect } from "react";
import { MappedFieldsProps } from "../types";
import MapDropArea from "./MapDropArea";
import Field from "./Field";
import { useTranslation } from "react-i18next";
import { useMapperContext } from "../Context/MapperContext";

const MappedFields: React.FC<MappedFieldsProps> = ({ systemField, mappedFields, index }) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<boolean>(false);

  const { onDrop, isChecked } = useMapperContext();
  const { t, i18n } = useTranslation();

  const checkActiveFieldInSessionStorage = () => {
    const activeFieldValue = sessionStorage.getItem("activeField");
    setActiveField(!!activeFieldValue);
  };

  useEffect(() => {
    checkActiveFieldInSessionStorage();

    const interval = setInterval(() => {
      checkActiveFieldInSessionStorage();
    }, 50);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === "activeField") {
        checkActiveFieldInSessionStorage();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const handleDrop = () => {
    onDrop(systemField.value, index);
    sessionStorage.removeItem("activeField");
    setDragging(false);
    checkActiveFieldInSessionStorage();
  };

  const getFormattedDate = () => {
    const locale = i18n.language === "en" ? "en-GB" : i18n.language;
    return new Date().toLocaleDateString(locale, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setDragging(false);
    }
  };

  if (isChecked && index === 3) {
    return (
      <>
      <div className="h-[45px] flex 2xl:hidden pointer-events-none"/>
      <div className="h-[45px] w-[320px] rounded-[0.4rem] p-[15px] -ml-8 2xl:ml-0 mb-[15px] opacity-100 whitespace-nowrap border flex justify-center items-center">
        {getFormattedDate()}
      </div>
      </>
    );
  }

  return (
    <>
      {activeField && (mappedFields.length === 1 && (<div className="absolute w-[160px] h-[45px] z-10 -translate-x-[33px] translate-y-[45px] 2xl:translate-x-0 2xl:translate-y-0"></div>))}
      {activeField && (mappedFields.length === 2 && (<div className="absolute w-[320px] h-[45px] z-10 -translate-x-[33px] translate-y-[45px] 2xl:translate-x-0 2xl:translate-y-0"></div>))}
      <div className="absolute w-[45px] h-[45px] ml-[320px] z-10 -translate-x-[33px] translate-y-[45px] 2xl:translate-x-0 2xl:translate-y-0"></div>
      <div className="absolute w-[365px] h-[15px] mt-[45px] z-10 -translate-x-[33px] translate-y-[45px] 2xl:translate-x-0 2xl:translate-y-0"></div>
      {mappedFields.length > 0 && activeField && ('"' + mappedFields[0].title+ '"' === sessionStorage.getItem("activeField") ? (
        <div className="absolute w-[320px] h-[45px] z-10 -translate-x-[33px] translate-y-[45px] 2xl:translate-x-0 2xl:translate-y-0"></div>
      ) : null)}

<div className="h-[45px] flex 2xl:hidden pointer-events-none"/>

      <div
        key={systemField.value}
        className="flex flex-col -ml-8 2xl:ml-0"
        onDrop={() => handleDrop()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={(e) => {
          e.preventDefault();
          setDragging(true);
        }}
      >
        {mappedFields.length === 0 && (
          <div className="flex flex-row">
            <MapDropArea
              onDrop={() => handleDrop()}
              className={`${dragging ? "border-black text-black" : ""} transition-all duration-300 w-[320px]`}
              dragging={true}
            />
            <MapDropArea
              onDrop={() => {}}
              className={`w-[0px] opacity-[0] overflow-hidden transition-all duration-300`}
              dragging={dragging}
            />
          </div>
        )}

        {mappedFields.length === 1 && (
          <>
            <div className="flex flex-row gap-1">
              <Field
                key={mappedFields[0].title + index}
                field={mappedFields[0]}
                className={`transition-all duration-300 ${
                  dragging ? "w-[158px]" : "w-[320px]"
                }`}
              />
              <MapDropArea
                onDrop={() => handleDrop()}
                className={`${
                  dragging ? "w-[158px] opacity-[100] border-black text-black" : "w-[0px] opacity-[0]"
                } overflow-hidden transition-all duration-300`}
                dragging={dragging}
              />
            </div>
          </>
        )}

        {mappedFields.length === 2 && (
          <div className="flex flex-row gap-1">
            {mappedFields.map((mappedField, idx) => (
              <Field
                key={mappedField.title + idx}
                field={mappedField}
                className="w-[158px]"
              />
            ))}
            <MapDropArea
              onDrop={() => {}}
              className={`w-[0px] opacity-[0] overflow-hidden transition-all duration-300`}
              dragging={dragging}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MappedFields;
