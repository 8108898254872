import SystemField from "./SystemField";
import { useTranslation } from "react-i18next";
import { useMapperContext } from "../Context/MapperContext";

const SystemColumn = () => {
  const {t} = useTranslation()
  const {systemFields} = useMapperContext();
  
  return (
    <section className="my-2.5 w-[0px] 2xl:w-[320px]">
      <h2 className="flex items-center whitespace-nowrap my-3.5 text-[18px] font-semibold">
      {t('mapping:systemFields')}
      </h2>

      {systemFields.map((systemField, index) => {
        return <SystemField key={index} systemField={systemField} />;
      })}
    </section>
  );
};

export default SystemColumn;
