import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import info from "../../../../Assets/info.svg";
import Import from "../../../../Assets/import.svg";
import mailReply from "../../../../Assets/mail-reply.svg";
import { useTranslation } from "react-i18next";
import { DeviceFrameset } from "react-device-frameset";
import { useAppSelector } from '../../../../Redux/store';
import { useImportCardsMutation } from '../../../../Redux/Api/Organisations';
import { useUploadMutation } from '../../../../Redux/Api/Api'
import { QRCodeCanvas } from 'qrcode.react';
import IconSVG from "../../../../Assets/icon.svg?component";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faChevronDown, faCalendarDays } from "@fortawesome/pro-solid-svg-icons";
import ErrorScreenWidth from "./MapperComponents/ErrorScreenWidth";
import { motion } from "framer-motion";
import Loader from "../../../Shared/Loader";
import AlertModal from "./AlertModal";
import { JWT } from '../../../../Utils/JWT';
import { useDownloadProcessedFileMutation, useFetchCsvDataMutation } from "../../../../Redux/Api/PatrickAiApi";
import Page404 from "../../Page404";
import MobileApp from "../../../Shared/App/MobileApp";
import MyCard from "../../../Shared/App/MyCard";

const Preview: React.FC = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedTime, setSelectedTime] = useState<string>("0");
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const [uploadFile] = useUploadMutation()
    const [importCards] = useImportCardsMutation();
    const location = useLocation();
    const [busy, setBusy] = useState(false);
    const [csvDataPreview, setCsvDataPreview] = useState([])
    const [csvData, setCsvData] = useState<string[][]>([])
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false)
    const [alertTitle, setAlertTitle] = useState<string>("")
    const [alertMessage, setAlertMessage] = useState<string>("")
    const [fileBlob, setFileBlob] = useState('')
    const [downloadProcessedFile] = useDownloadProcessedFileMutation();
    const [fetchCsvData] = useFetchCsvDataMutation();
    const theme = useAppSelector((s) => s.organisation.settings?.theme)
    const first_name = JWT.read<string>('given_name')

    const handleImportAndMail = () => {
        setShowModal(true);
        setFirstShow(true);
    };

    useEffect(() => {
        if (location.state?.data) {
            setCsvDataPreview(location.state.data);
            sessionStorage.setItem("dataPreview", JSON.stringify(location.state.data));
        } else {
            const storedData = sessionStorage.getItem("dataPreview");
            if (storedData) {
                setCsvDataPreview(JSON.parse(storedData));
            }
        }
    }, []);


    useEffect(() => {
        async function updateCsv() {
            const data = await fetchCsvData(org_id).unwrap();
            setCsvData(data.response)
        }
        updateCsv();
    }, [])


    const closeModal = () => {
        setShowModal(false);
    };

    const formatDateString = (dateString: string): string => {
        return dateString.replace(/\s*\(.*?\)\d{2}:\d{2}/, '');
    };

    const navigateToEditor = () => {
        sessionStorage.setItem('openEditor', "true");
        navigate("/" + i18n.language + "/dashboard/cards/import/map#editor");
    }

    const isDateAfterActiveFromDate = (): [boolean, string | undefined] => {
        const headers: string[] = csvData[0];
        const geldigVanIndex: number = headers.indexOf("Geldig van");

        const geldigVanDates: string[] = csvData.slice(1)
            .map((row: string[]) => row[geldigVanIndex].trim())
            .filter((date: string) => date !== "");

        const validGeldigVanDates: Date[] = geldigVanDates.map(dateStr => {
            const normalizedDateStr = dateStr.replace(/[\/\.]/g, '-');
            const [day, month, year] = normalizedDateStr.split('-').map(Number);
            return new Date(year, month - 1, day);
        });

        const comparisonDate: Date = selectedDate || new Date();
        const isValid: boolean = validGeldigVanDates.every(date => comparisonDate >= date);
        const furthestDate: Date | null = validGeldigVanDates.length > 0
            ? new Date(Math.max(...validGeldigVanDates.map(date => date.getTime())))
            : null;

        return [isValid, furthestDate?.toLocaleDateString()];
    };


    const handleShowAlert = () => {
        setIsAlertModalOpen(true);
    };

    const handleCloseModal = () => {
        setAlertMessage("")
        setAlertTitle("")
        setIsAlertModalOpen(false);
    };

    const getFileToUpload = async () => {
        try {
            const blob = await downloadProcessedFile(org_id).unwrap();
            return blob;
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const splitCSV = (csvContent: string, rowsPerChunk: number): string[] => {
        const rows = csvContent.split("\n");
        const header = rows[0];
        const chunks: string[] = [];

        for (let i = 1; i < rows.length; i += rowsPerChunk) {
            const chunk = [header, ...rows.slice(i, i + rowsPerChunk)].join("\n");
            chunks.push(chunk);
        }

        return chunks;
    };

    const toggleChatVisibility = () => {
        localStorage.setItem('showSuggestions', JSON.stringify(false));
        sessionStorage.setItem('openEditor', "true");
        sessionStorage.setItem('setImportComplete', "true");
    };

    const handleImport = async (send: boolean, scheduleActivation: boolean = false) => {
        if (busy) return;
        setBusy(true);

        const isAfterActiveDate = isDateAfterActiveFromDate();
        if (send && !isAfterActiveDate[0] || scheduleActivation && !isAfterActiveDate[0]) {
            setAlertMessage(t("mapping:alertIncorrectCardDate", { correct_date: `${isAfterActiveDate[1]}` }) as string);
            setAlertTitle(t("mapping:alertIncorrectCardDateFix") as string);
            handleShowAlert();
            setBusy(false);
            return;
        }

        let selectedDateTime = "";
        if (scheduleActivation && selectedDate !== null) {
            const formatDate = selectedDate.toDateString();
            selectedDateTime = formatDateString(formatDate);
        }

        const uploadedFile = await getFileToUpload();

        if (uploadedFile != null) {
            try {
                const textContent = await uploadedFile.text();
                const rowsPerChunk = 3000;
                const chunks = splitCSV(textContent, rowsPerChunk);

                await Promise.all(
                    chunks.map(async (chunk, index) => {
                        const file = new File([chunk], `imported_cards_part${index + 1}.csv`, { type: 'text/csv' });

                        const file_id = await uploadFile({ file }).unwrap();
                        // @ts-ignore
                        try {
                            await importCards([org_id, file_id, send, selectedDateTime]).unwrap();
                        } catch (importError: any) {
                            if (importError?.status === 504) {
                                toggleChatVisibility();
                                window.location.replace(`/${i18n.language}/dashboard/cards/list#filters-none`);
                            }
                        }
                    })
                );

                toggleChatVisibility();
                window.location.replace(`/${i18n.language}/dashboard/cards/list#filters-none`);
            } catch (error) {
                console.error("Error processing file:", error);
            } finally {
                setTimeout(() => {
                    setBusy(false);
                }, 2000);
            }
        } else {
            console.error("No file retrieved");
            setBusy(false);
        }
    };

    const openDateSelect = () => {
        setShowDropdown(!showDropdown);
    };

    const handleDateChange = (date: Date) => {
        setSelectedDate(date);
        setShowDropdown(false);
    };

    const handleTimeSelect = (time: string) => {
        setSelectedTime(time);
    };

    const getCalendarLocale = () => {
        return i18n.language === "en" ? "en-GB" : i18n.language;
    };

    const formatDate = (date: Date) => {
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "long",
            year: "numeric",
        };
        return date.toLocaleDateString(getCalendarLocale(), options);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        if (!theme) return

        setFileBlob(theme.icon)
    }, [theme])


    function getPreviewData() {

        if (!csvDataPreview || !Array.isArray(csvDataPreview) || csvDataPreview.length < 2) {
            return [];
        }

        const previewUser = csvDataPreview;
        const name = `${previewUser[0]} ${previewUser[1]}`;
        const type = `${previewUser[6]}`;
        const birthdate = `${previewUser[7]}`;
        const location = `${previewUser[8]}`;

        const data = [
            { label: `${t("mapping:initials/firstname")}`, preview: name.trim() || "---" },
            { label: `${t("mapping:types")}`, preview: type.trim() || "---" },
            { label: `${t("mapping:dateOfBirth")}`, preview: birthdate.trim() || "---" },
            { label: `${t("mapping:location")}`, preview: location.trim() || "---" },
        ];

        return data;
    }

    function getFirtsNameData() {
        if (!csvDataPreview || !Array.isArray(csvDataPreview) || csvDataPreview.length < 2) {
            return [];
        }

        const previewUser = csvDataPreview;
        const name = `${previewUser[0]}`;

        return name;
    }

    if (!csvDataPreview || !Array.isArray(csvDataPreview) || csvDataPreview.length < 2) {
        return <Page404 />;
    }

    return (
        <>
            {isAlertModalOpen && <AlertModal isOpen={isAlertModalOpen} onClose={handleCloseModal} action={navigateToEditor} actionTitle={`${t("mapping:adjustEditorField")}`} alertTitle={alertTitle} alertMessage={alertMessage} />}
            {busy ?
                <div className="w-full h-full flex flex-col justify-center items-center gap-4">
                    <Loader />
                    <h1 className="font-bold text-2xl">{t('mapping:importLoading')}</h1>
                </div>
                :
                <>
                    <div className="flex flex-col xl:flex-row justify-center scale-75 sm:scale-100 -ml-14 sm:ml-0 -mt-40 sm:mt-0">
                        <MobileApp activeItem="mycard">
                            <MyCard customCardData customNameStatic={getFirtsNameData() as string}>
                                {getPreviewData().map((item, index) => (
                                    <div key={index} className="flex flex-col text-start break-all">
                                        <p className="text-gray-500 text-[14px]">{item.label}</p>
                                        <h2 className="text-[14px] font-app-bold">{item.preview}</h2>
                                    </div>
                                ))}
                            </MyCard>
                        </MobileApp>
                        <div className="flex flex-col ml-4 xl:ml-8 gap-2">
                            <h1 className="text-[#222323] text-3xl">{t("mapping:preview")}</h1>
                            <div className="w-[400px] h-[auto] pb-[20px] border-2 border-[#3273F6] p-2 rounded-[0.4rem]">
                                <div className="flex mb-2 gap-2">
                                    <img src={info} alt="detail" />
                                    <p className="font-bold">{t("mapping:comments")}</p>
                                </div>
                                <p className="tracking-normal">
                                    {t("mapping:infoPreview")}
                                </p>
                            </div>
                            <div className="flex w-[400px] justify-between">
                                <button
                                    className="font-bold text-[#EA325C] border-2 border-[#EA325C] rounded-[0.4rem] p-2 flex gap-2 whitespace-nowrap z-10 bg-white hover:bg-red-200"
                                    onClick={() => navigate(`/${i18n.language}/dashboard/cards/import`)}
                                >
                                    {t("mapping:stop")}
                                </button>
                                <button className="font-bold text-[#3273F6] border-2 border-[#3273F6] rounded-[0.4rem] p-2 flex gap-2 whitespace-nowrap z-10 bg-white hover:bg-blue-200" onClick={() => handleImport(false)}>
                                    <img src={Import} alt="Import" />
                                    {t("mapping:import")}
                                </button>
                                <button
                                    className="font-bold text-white bg-[#02AA01] rounded-[0.4rem] p-2 flex gap-2 whitespace-nowrap z-10 hover:bg-[#018900]"
                                    onClick={handleImportAndMail}
                                >
                                    <img src={mailReply} alt="Mail" className="w-6" />
                                    {t("mapping:importAndMail")}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Popup */}

                    <style>{`
                        .spawn { animation: spawn 0.3s ease-in;   }
                        @keyframes spawn { 0% { transform: scale(0); } 100% { transform: scale(1); } }
                        .despawn { animation: despawn 0.3s ease-out; }
                        @keyframes despawn { 0% { transform: scale(1); } 100% { transform: scale(0); } }
                    `}</style>
                    <div className={`fixed top-0 left-0 w-full h-full bg-black transition-all ${showModal ? 'opacity-50' : 'opacity-0 pointer-events-none'} flex justify-center items-center z-[105]`} onClick={closeModal} />
                    <div className={`${showModal ? '' : 'pointer-events-none'} fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center z-[105]`}>
                        <div className={`${!firstShow ? 'scale-0' : (showModal ? 'spawn' : 'despawn scale-0')} bg-white rounded-[0.4rem] p-8 w-[400px] lg:w-[850px]`}>
                            <div className="flex justify-between">
                                <h1 className="text-3xl font-bold mb-2">{t("mapping:planEmail")}</h1>
                                <button className="cursor-pointer h-10" onClick={closeModal}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            </div>

                            <div className="border border-[#3273F6] bg-[#F5F8FB] rounded-[0.4rem] p-5 mb-2">
                                <div className="flex mb-2 gap-2">
                                    <img src={info} alt="detail" />
                                    <p className="font-bold">{t("mapping:comments")}</p>
                                </div>
                                <p className="tracking-normal">
                                    {t("mapping:infoPreview")}
                                </p>
                            </div>
                            <h2 className="font-bold mb-2">{t("mapping:when")}</h2>

                            <div ref={dropdownRef} className="relative mb-2 ">
                                <button
                                    onClick={openDateSelect}
                                    className="flex justify-between items-center w-full h-12 bg-white border border-[#E5E7EB] px-4 rounded-[0.4rem]"
                                >
                                    <span><FontAwesomeIcon icon={faCalendarDays} className="mr-2" />{selectedDate ? formatDate(selectedDate) : t("mapping:noDate")}</span>
                                    <FontAwesomeIcon icon={faChevronDown} className={`transition-transform duration-200 ${showDropdown ? 'transform rotate-180' : ''}`} />
                                </button>
                                <motion.div initial={{ height: 'auto' }} animate={{ height: !showDropdown ? 'auto' : 0 }} transition={{ duration: 0.5, ease: 'easeInOut' }} className="h-0 absolute z-10 -ml-2 lg:ml-[27.2rem] bottom-[53px] mt-1 overflow-hidden">
                                    <div className="bg-white border border-[#E5E7EB] overflow-hidden rounded-[0.4rem] shadow-lg">
                                        <Calendar
                                            onChange={handleDateChange}
                                            value={selectedDate}
                                            locale={getCalendarLocale()}
                                            minDate={new Date()}
                                            maxDate={new Date(new Date().setDate(new Date().getDate() + 365))}
                                        />
                                    </div>
                                </motion.div>
                            </div>
                            <div className="flex flex-col lg:flex-row gap-2">
                                <button onClick={() => handleImport(true)} className="rounded-[0.4rem] w-full lg:w-[32.8%] border border-[#3273F6] border-[2px] hover:bg-[#3273F6] hover:text-white text-[#3273F6] h-12">{t("mapping:directly")}</button>
                                <button onClick={() => handleImport(false, true)} className={`${selectedDate ? '' : 'opacity-50 pointer-events-none'} rounded-[0.4rem] w-full lg:w-[66.2%] bg-[#3273F6] text-white hover:bg-[#295fcc] h-12`}>{t("mapping:schedule")} {selectedDate ? formatDate(selectedDate) : t("mapping:noDate")}</button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default Preview;