import { faPlus, faQuestionCircle, faXmark, faFileImport } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import moment from 'moment'
import React, { useContext, useEffect, useState, useTransition } from 'react'
import { useGetNewsQuery, usePullFeedMutation, usePutNewsFeedMutation } from '../../../../Redux/Api/Organisations';
import { putFeedLocal } from '../../../../Redux/State/Organisation'
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'

import Form from '../../../Shared/Form/Form'
import Input from '../../../Shared/Form/Input'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Header from '../../../Shared/Table/Header'
import Row from '../../../Shared/Table/Row'
import Table from '../../../Shared/Table/Table'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import { useNavigate } from "react-router-dom";
import { useDeleteNewsMutation } from "../../../../Redux/Api/News";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from '@mui/material'

const NewsPage = () => {

    const { withModal } = useContext(ModalContext)
    const { t, i18n } = useTranslation()

    const feed = useAppSelector((s) => s.organisation.settings?.news?.feed)
    const org_id = useAppSelector((s) => s.organisation.id)!
    const { data: news, refetch } = useGetNewsQuery(org_id)
    const [successMessage, setSuccessMessage] = useState('')
    const [successAddMessage, setSuccessAddMessage] = useState('')

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [putFeed] = usePutNewsFeedMutation()
    const [pullFeed] = usePullFeedMutation()
    const [deleteNews] = useDeleteNewsMutation()

    const [busy, setBusy] = useState(false)
    const [error, setError] = useState('')
    const [rssInfoOpen, setRssInfoOpen] = useState(false)
    const [selectedPopup, setSelectedPopup] = useState<{ img: string, url: string } | null>(null);

    const isSmallScreen = useMediaQuery('(min-width: 475px)');

    useEffect(() => {
        if (sessionStorage.getItem('successMessage')) {
            setSuccessAddMessage(sessionStorage.getItem('successMessage')!);
            sessionStorage.removeItem('successMessage');
        }
    }, [org_id])

    useEffect(() => {
        if (org_id) {
            refetch();
        }
    }, [org_id, refetch]);

    const submit = async ({ feed }: { feed: string }) => {
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await putFeed({ id: org_id, feed }).unwrap()

            await pullFeed(org_id).unwrap()

            refetch()

            dispatch(putFeedLocal(feed))
            setSuccessMessage(t('organisation:news:successImport') as string)
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setTimeout(() => setBusy(false), 200)
    }

    const deleteNewsAction = async (id: string) => {
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await deleteNews(id)

            if (org_id)
                refetch()
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
        }

        setBusy(false)
    }

    const showPopup = (img: string, url: string) => {
        setSelectedPopup({ img, url });
    }

    const closePopupPreview = () => {
        setSelectedPopup(null);
    }

    if (!news) return <LoadingPage />

    return (
        <>
            <Title text={t('organisation:news:title')} subtitle={t('organisation:news:subtitle') as string} textNotBold />
            <StatusPopups type='error' text={error} />
            <StatusPopups type='success' text={successMessage} />
            <StatusPopups type='success' text={successAddMessage} />
            <BusyArea busy={busy}>
                <Button
                    title={t('organisation:news:add')}
                    icon={faPlus}
                    iconright
                    action={() => navigate(`/${i18n.language}/dashboard/news/create`)}
                    className='absolute -top-4 float-right absolute'
                />
                <br />
                <Form submit={submit} className=''>
                    <div className='hyphens-auto'>
                        {t('organisation:news:rssMessage')}
                        <FontAwesomeIcon onClick={() => setRssInfoOpen(s => !s)} className='cursor-pointer opacity-60 ml-1' icon={faQuestionCircle} />
                    </div>
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{
                            scale: rssInfoOpen ? 1 : 0,
                            opacity: rssInfoOpen ? 1 : 0,
                            height: 'auto'
                        }}
                        transition={{ duration: 0.3 }}
                        className='origin-center bg-light-100 p-4 mt-2 rounded-[0.4rem]'
                    >
                        {t('organisation:news:rssMessageDescription')} <a target='_blank' className='text-accent hover:text-accent-light' href='https://x-interactive.nl/contact/' >{t('organisation:news:ask')}</a>.
                    </motion.div>

                    <Input
                        className='mt-2'
                        id='feed'
                        type='url'
                        placeholder='https://'
                        initial={feed}
                        label='RSS Feed URL' />
                    <div className='flex gap-10'>
                        <Button icon={faFileImport} title={t('organisation:news:import')} />
                    </div>
                </Form>
                <Table className='mb-8' withSearch>
                    <Header>
                        <Column name={t('organisation:news:tableTitle')} />
                        <Column hidden={!isSmallScreen} name={t('organisation:news:url')} />
                        <Column initialSort={'up'} name={t('organisation:news:date')} />
                    </Header>
                    <Data>
                        {news.map((item, index) => (
                            <Row
                                firstButton='action'
                                lastButton='deleteAction'
                                key={index}
                                actionDisabled={!item.url}
                                action={() => showPopup(item.image, item.url)}
                                data={
                                    !isSmallScreen
                                        ? [item.title, moment(item.date).format('DD-MM-YYYY')]
                                        : [item.title, item.url ? item.url : '-', moment(item.date).format('DD-MM-YYYY')]
                                }
                                index={index}
                                editUrl={`/${i18n.language}/dashboard/news/edit/` + item.id}
                                deleteAction={() => deleteNewsAction(item.id)} />
                        ))}
                    </Data>
                </Table>
            </BusyArea>

            <AnimatePresence>
                {selectedPopup && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={closePopupPreview} className="fixed top-0 left-0 w-full h-full bg-black/50 flex flex-col lg:flex-row z-[1000]">
                        <FontAwesomeIcon icon={faXmark} className="absolute top-4 right-4 text-4xl text-white cursor-pointer" />
                        {selectedPopup.url === '' ? (
                            <div className='w-[100vw] h-[100vh] flex item items-center justify-center'>
                                <motion.div
                                    initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                                    animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                                    exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                                    className='flex flex-col z-[110] bg-light-200 rounded-[0.4rem] max-h-[calc(100vh-10px)] max-w-[calc(100vw-100px)]'>
                                    <div className='flex flex-col m-10 lg:m-20'>
                                        <Title className='mt-0 md:mt-0' text={t('organisation:news:noLink')} />
                                        <p className='mt-4'>
                                            {t('organisation:news:noLinkText')}
                                        </p>
                                        <div className='flex gap-4 mt-8'>
                                            <Button nosubmit title={t('cards:list:close')} action={() => closePopupPreview()} />
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        ) : (
                            <>
                                <div className='w-[100vw] h-[100vh] flex item items-center justify-center'>
                                    <motion.div
                                        initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                                        animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                                        exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className='flex flex-col z-[110] bg-light-200 rounded-[0.4rem] max-h-[calc(100vh-10px)] max-w-[calc(100vw-100px)]'>
                                        <div className='flex flex-col m-10 lg:m-20'>
                                            <Title className='mt-0 md:mt-0' text={t('organisation:news:openLink')} />
                                            <p className='mt-4'>
                                                {t('organisation:news:openAddedNewsLink')}
                                            </p>
                                            <div className='flex gap-4 mt-8'>
                                                <Button nosubmit title={t('cards:list:yes')} action={() => window.open(selectedPopup.url, '_blank')} />
                                                <Button secondary nosubmit title={t('cards:list:no')} action={() => closePopupPreview()} />
                                            </div>
                                        </div>
                                    </motion.div>
                                </div>
                            </>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default NewsPage
