import React, { useContext, useEffect, useState } from 'react';
import { DataGrid as MuiDataGrid, DataGridProps as MuiDataGridProps, GridFooter } from "@mui/x-data-grid";
import { withStyles } from "@mui/styles";
import { useAppDispatch } from "../../Redux/store";
import { setDataGridSelection } from "../../Redux/Slices/Datagrid";
import DataGridNoRowsOverlay from "./DataGridNoRowsOverlay";
import DataGridToolbar, { DataGridToolbarAction } from "./DataGridToolbar";
import { Pagination, TableFooter, TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";

export type DataGridProps = MuiDataGridProps & {
    actions?: Array<DataGridToolbarAction>,
    onSelectionChanged?: (selection: Array<string | number>) => void,
    data?: any[]
};

function DataGrid({ actions, onSelectionChanged = () => { }, data = [], ...props }: DataGridProps) {
    const dispatch = useAppDispatch();
    const [selectionModel, setSelectionModel] = useState<Array<string | number>>([]);
    const [fullSelection, setFullSelection] = useState<Array<string | number>>([]);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const { t } = useTranslation();
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

    const activeFilters = localStorage.getItem('activeFilters');
    let isFiltered = false;

    if (activeFilters !== null) {
        try {
            const parsedFilters = JSON.parse(activeFilters);

            if (Array.isArray(parsedFilters) && parsedFilters.length > 0) {
                isFiltered = parsedFilters.some(filter => filter.value !== "");
            }
        } catch (e) {
            isFiltered = false;
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener('resize', handleResize); 

        return () => {
            window.removeEventListener('resize', handleResize); 
        };
    }, []);

    useEffect(() => {
        const newFullSelection = [...fullSelection];
        newFullSelection.push(...selectionModel.filter(id => !newFullSelection.includes(id)));
        setFullSelection(newFullSelection);
        setSelectAll(newFullSelection.length === data.length);
    }, [selectionModel, data]);

    useEffect(() => {
        setSelectionModel(fullSelection);
        dispatch(setDataGridSelection(fullSelection));
    }, [selectAll, data, dispatch]);

    const handleSelectAll = () => {
        if (!selectAll) {
            const newSelection = data;
            setFullSelection(data)
            setSelectionModel(newSelection);
            dispatch(setDataGridSelection(newSelection));
        } else {
            setFullSelection([])
            setSelectionModel([]);
            dispatch(setDataGridSelection([]));
        }
        setSelectAll(!selectAll);
    };

    const handleRowClick = (params: any) => {
        if (params.field == 'actions') {
            return;
        }

        const selectedIndex = selectionModel.indexOf(params.id);
        let newSelection: any[] = [...selectionModel];

        if (selectedIndex === -1) {
            newSelection.push(params.id);
        } else {
            newSelection.splice(selectedIndex, 1);
        }

        setSelectionModel(newSelection);
        dispatch(setDataGridSelection(newSelection));
        setFullSelection(newSelection)
    };
    const handleHeaderClick = (params: any) => {
        if (params.field !== '__check__') {
            return;
        }
        let newSelection: any[] = [...selectionModel];

        const allSelected = props.rows.every(row => selectionModel.includes(row.id));

        if (allSelected) {
            newSelection = newSelection.filter(id => !props.rows.some(row => row.id === id));
        } else {
            const newIds = props.rows.map(row => row.id).filter(id => !newSelection.includes(id));
            newSelection.push(...newIds);
        }

        setSelectionModel(newSelection);
        dispatch(setDataGridSelection(newSelection));
        setFullSelection(newSelection);
    };

    return (
        <MuiDataGrid
            key={windowSize}
            pageSize={25}
            rowsPerPageOptions={[25]}
            components={{
                NoRowsOverlay: DataGridNoRowsOverlay,
                Toolbar: () => (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingBottom: 3 }}>
                        <DataGridToolbar actions={actions} data={Array.isArray(data) ? data.length : 0} select={selectionModel.length} selectAll onSelectAll={handleSelectAll} />
                    </div>

                ),
                Footer: (footerProps: any) =>
                    <div style={{ display: 'flex', borderTop: '1px solid #D7E3F2', }}>
                        <div className='flex items-center' style={{ marginLeft: 'auto' }}>
                            {isFiltered &&
                                <>
                                    <div className='text-sm'>{t('cards:list:filtered')}</div>
                                    <div className='text-sm ml-5'>-</div>
                                </>
                            }
                            <GridFooter style={{ border: 'none' }} />
                        </div>
                    </div>,
            }}
            onCellClick={handleRowClick}
            selectionModel={selectionModel}
            onColumnHeaderClick={handleHeaderClick}
            {...props}
            sx={{
                '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-row:focus, & .MuiDataGrid-row:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                },
                '& .MuiCheckbox-root:focus, & .MuiCheckbox-root:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-cellCheckbox:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderCheckbox:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root:focus-within': {
                    outline: 'none',
                },
                '& .MuiButtonBase-root:focus, & .MuiButtonBase-root:focus-within': {
                    outline: 'none',
                },
                '& .MuiButton-root:focus, & .MuiButton-root:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-columnHeader[data-field="actions"], & .MuiDataGrid-columnHeader[data-field="block"], & .MuiDataGrid-columnHeader[data-field="photoId"]': {
                    cursor: 'default',
                    pointerEvents: 'none',
                },
                '& .MuiDataGrid-cell[data-field="emailactivation"] svg, & .MuiDataGrid-cell[data-field="activated"] svg, & .MuiDataGrid-cell[data-field="photoId"] svg': {
                    marginLeft: '-5px',
                },
                '& .MuiDataGrid-cell[data-field="actions"]': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                },
                '& .MuiDataGrid-cell[data-field="__check__"]': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        />
    );
}

// @ts-ignore
const StyledDataGrid = withStyles({
    root: {
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-cell': {
            display: 'flex',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },
        '& .MuiCheckbox-root.Mui-checked': {
            color: "#0175FF !important",
        },
        '& .MuiCheckbox-root': {
            color: "#D2DCEA",
        },
        '& .MuiDataGrid-columnHeaders': {
            backgroundColor: "#F4F8FB",
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            alignItems: 'center',
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: "#F4F8FB",
            '&:hover': {
                backgroundColor: "#F4F8FB",
            },
        },
        '& .MuiDataGrid-iconSeparator': {
            visibility: 'hidden',
        },
    },
})(DataGrid);

export default StyledDataGrid;
