import FieldsColumn from "../MapperComponents/FieldsColumn";
import MappedColumn from "../MapperComponents/MappedColumn";
import SystemColumn from "../MapperComponents/SystemColumn";

function Mapper() {

  return (
    <>
      <div className="ml-2 md:ml-0 flex md:hidden">
        <FieldsColumn />
      </div>
      <div className="flex overflow-x-hidden ml-2 md:ml-0 gap-6">
        <SystemColumn />
        <MappedColumn />
        <div className="hidden md:flex">
          <FieldsColumn />
        </div>
      </div>
    </>
  );
}

export default Mapper;
