import { motion } from 'framer-motion'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Strings } from '../../../Strings/nl'
import Calendar from 'react-calendar'
import { useTranslation } from "react-i18next";

export interface CalendarInputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label: string,
    sublabel?: string,
    id?: string,
    initial?: Date | string,
    required?: boolean,
    valueChange?: (value: Date) => void
    valuelabel?: string
    placeholder?: string
    inputClasses?: string
    lableInside?: string
    quickSelection?: boolean
    submitted?: boolean
}

const firstToUpperCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

const CalendarInput = (props: CalendarInputProps) => {
    const ref = useRef(null)
    const { label, required, initial, valueChange, sublabel, id, valuelabel, className, placeholder, quickSelection = true, lableInside, inputClasses, submitted, ...restProps } = props
    const { t, i18n } = useTranslation()

    const labelOrId = id || label
    const [value, setValue] = useState(initial && initial !== "9999-01-01T00:00:00+00:00" ? new Date(initial) : null)
    const [isOpen, setIsOpen] = useState(false)
    const [labelvalue, setlabelvalue] = useState(valuelabel ? valuelabel : null)
    const currentLanguage = i18n.language;

    const getFormattedDate = () => {
        const locale = i18n.language === "en" ? "en-GB" : i18n.language;
    
        if (value && value.getFullYear() === 9999 && value.getMonth() === 0 && value.getDate() === 1) {
            return '\u00A0';
        }
    
        return value 
            ? value.toLocaleDateString(locale, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            }) 
            : (placeholder ? placeholder : ' ');
    };
    

    const onChange = (date: Date) => {
        setValue(date)
        if (valueChange) valueChange(date)
        setIsOpen(false)
    }
    useEffect(() => {
        setlabelvalue(valuelabel ? valuelabel : null)
    }, [valuelabel])

    useEffect(() => {
        const checkIfClickedOutside = (e: { target: any }) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            // @ts-ignore
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    })

    const handleInputChange = (e: { target: { value: string | number | Date } }) => {
        const newDate = new Date(e.target.value);
        if (!isNaN(newDate.getTime())) {
            setValue(newDate);
            if (valueChange) valueChange(newDate)

        }
    };

    const handleQuickSelection = (type: string) => {
        const today = new Date();
        let newDate: Date | null = null;
    
        switch (type) {
            case 'today':
                newDate = today;
                break;
            case 'tomorrow':
                newDate = new Date(today);
                newDate.setDate(today.getDate() + 1);
                break;
            case 'nextMonth':
                newDate = new Date(today);
                newDate.setMonth(today.getMonth() + 1);
                break;
            case 'nextYear':
                newDate = new Date(today);
                newDate.setFullYear(today.getFullYear() + 1);
                break;
            case 'noDate':
                newDate = new Date(9999, 0, 1);
                break;
            default:
                break;
        }
    
        setValue(newDate);
        if (valueChange && newDate) valueChange(newDate);
    };
    

    return (
        <div {...restProps} ref={ref} className={`flex flex-col ${className}`}>
            <label htmlFor={labelOrId}>{label}{required && <span className='text-red-400'>*</span>} {sublabel && <span className='opacity-70 text-sm'>({sublabel})</span>}</label>
            <div className='flex flex-grow relative'>
                <div onClick={() => setIsOpen(!isOpen)} className={`flex items-center cursor-pointer rounded-[0.4rem] flex-grow p-2 px-3 ${submitted && !value ? 'border-red-500 border' : 'border-[1px] border-black border-opacity-15'} ${value ? '' : 'text-gray-300'} ${inputClasses}`}>
                    {lableInside}{lableInside && value ? ': ' : ''}{getFormattedDate()}
                </div>

                <motion.div
                    layout
                    className={`absolute mb-12 bottom-0 overflow-hidden border-2 ${isOpen ? 'min-h-[340px] opacity-100' : 'h-0 opacity-0 transition-opacity duration-1000'} flex bg-light-200 rounded-md z-40`}>
                    <input className='border-b' type="date" value={value ? value.toISOString().split('T')[0] : undefined} onChange={handleInputChange} style={{ position: "absolute", width: 500, height: 30, padding: 5 }} />
                    <Calendar defaultValue={value || undefined} onChange={onChange} value={value} locale={currentLanguage === 'en' ? 'en-GB' : `${currentLanguage}-${currentLanguage.toUpperCase()}`} className={'mt-7'} />
                    {quickSelection && (
                        <div className='border-l bg-light-100 py-1 px-2 text-black z-[1000] hidden sm:flex flex-col justify-between'>
                            <div className='flex flex-col gap-1'>
                                <div className='px-1 mb-2'>
                                    {t('calendar:quickSelect')}
                                </div>
                                <div className='cursor-pointer hover:bg-light-300 px-1 rounded' onClick={() => handleQuickSelection('today')}>
                                    {t('calendar:today')}
                                </div>
                                <div className='cursor-pointer hover:bg-light-300 px-1 rounded' onClick={() => handleQuickSelection('tomorrow')}>
                                    {t('calendar:tomorrow')}
                                </div>
                                <div className='cursor-pointer hover:bg-light-300 px-1 rounded' onClick={() => handleQuickSelection('nextMonth')}>
                                    {t('calendar:nextMonth')}
                                </div>
                                <div className='cursor-pointer hover:bg-light-300 px-1 rounded' onClick={() => handleQuickSelection('nextYear')}>
                                    {t('calendar:nextYear')}
                                </div>
                            </div>
                            {!required && (
                                <div className='cursor-pointer hover:bg-light-300 px-1 rounded' onClick={() => handleQuickSelection('noDate')}>
                                    {t('calendar:noDate')}
                                </div>
                            )}
                        </div>
                    )}

                </motion.div>
            </div>
            <input value={value?.toString()} type='hidden' name={labelOrId} id={labelOrId} />
            <input name={'@VALID@' + labelOrId} type="hidden" value={(!required || value != null) + ''} />
            {required && <motion.div animate={(!required || value != null) ? { height: '0rem' } : { height: '1.2rem' }} className='text-sm overflow-hidden text-red-400 mb-2'>{(required && value == null && t('required')) || ' '}</motion.div>}
        </div>
    )
}
export default CalendarInput;
