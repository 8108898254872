import { faCaretDown, faChevronDown, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, m, motion } from 'framer-motion';
import React, { useState, useEffect, useRef } from 'react';
import { ModalContext } from '../Modal/Modal';
import { useTranslation } from "react-i18next";

export interface DropdownInputProps extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onChange'> {
    label?: string;
    sublabel?: string;
    initial?: string;
    options: Array<{ id: string, name: string }>;
    onChange?: (value: string) => void;
    onOpen?: () => void;
    id?: string;
    up?: boolean;
    value?: string;
    placeholder?: string;
    rounded?: boolean;
    noCustom?: boolean;
    onDelete?: (id: string) => void;
    deleteText?: string;
}

const DropdownInput = (props: DropdownInputProps) => {
    const { label, rounded, sublabel, className, onOpen, up, onChange: onChangeOut, initial, options, id, value: propValue, placeholder, noCustom, onDelete, deleteText, ...restProps } = props;

    const labelOrId = id || label;
    const { t } = useTranslation();

    const [value, setValue] = useState(initial || '');
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const modalContext = React.useContext(ModalContext);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (propValue !== undefined) {
            setValue(propValue);
            setSearchText(propValue);
        }
    }, [propValue]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const onChange = (item: string) => {
        setIsOpen(false);
        setValue(item);
        if (onChangeOut) onChangeOut(item);
    };

    const onChangeValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const value = evt.target.value;
        if (!noCustom) {
            setValue(value);
            setSearchText(value);
            if (onChangeOut) onChangeOut(value);
        }
    };

    const doChangeOpen = (value: boolean) => {
        if (value && onOpen) onOpen();
        setIsOpen(value);
    };

    const filteredOptions = options.filter(option =>
        option.name.toLowerCase().includes(searchText.toLowerCase())
    );

    const sortedOptions = filteredOptions.sort((a, b) => {
        const isDigitA = /^\d/.test(a.name);
        const isDigitB = /^\d/.test(b.name);
        if (isDigitA && !isDigitB) return 1;
        if (!isDigitA && isDigitB) return -1;
        return a.name.localeCompare(b.name, 'en', { numeric: true });
    });

    return (
        <div {...restProps} className={`flex flex-col ${className}`} ref={dropdownRef}>
            <label htmlFor={labelOrId}>
                {label} {sublabel && <span className='opacity-70 text-sm'>({sublabel})</span>}
            </label>
            <div className='flex flex-grow relative cursor-pointer'>
                <div onClick={() => doChangeOpen(!isOpen)} className={`flex items-center border border-black rounded-[0.4rem] ${noCustom ? 'border-opacity-10' : 'border-opacity-30 '} flex-grow gap-2 p-1 ${isOpen && 'bg-light-100 z-[101]'}`}>
                    <input
                        className={`h-[30px] flex flex-grow bg-transparent focus:outline-none px-2 cursor-pointer`}
                        value={value}
                        onChange={(evt) => onChangeValue(evt)}
                        type='text'
                        autoComplete='off'
                        name={labelOrId}
                        id={labelOrId}
                        style={{ width: '100%' }}
                        placeholder={placeholder}
                        readOnly={noCustom}
                    />
                    <FontAwesomeIcon className={`${up ? (isOpen ? '' : 'rotate-180') : (isOpen ? 'rotate-180' : '')} text-sm ml-auto mr-2 text-black`} icon={faChevronDown} />
                </div>

                <AnimatePresence>
                    {isOpen && (
                        <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0 }}
                            transition={{ type: "smooth", duration: 0.2 }}

                            className={`absolute ${up ? 'bottom-11' : 'mt-11'} rounded-[0.4rem] border-[2px] border-black border-opacity-10 w-full overflow-y-auto max-h-48 flex flex-col bg-light-200 z-40`}>
                            {sortedOptions.length === 0 && (
                                <div key="empty" className='flex flex-grow first:pt-2 last:pb-2 p-1 px-4 cursor-pointer hover:bg-blue-500 transition-colors hover:text-white'>
                                    -
                                </div>
                            )}
                            {sortedOptions.map((option, i) => (
                                <div key={i} onClick={() => onChange(option.name)} className='flex flex-grow first:pt-2 last:pb-2 p-1 px-4 cursor-pointer hover:bg-blue-500 justify-between items-center transition-colors hover:text-white'>
                                    {onDelete ? (
                                        <>
                                            <p className='max-w-[90%] text-ellipsis overflow-hidden'>{option.name}</p>
                                            <FontAwesomeIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    modalContext.withModal({ title: t("areYouSure"), body: deleteText || t("confirmDeletion") }, () => {
                                                        onDelete && onDelete(option.id);
                                                    })();
                                                }}
                                                className='text-lg text-gray-500 hover:text-red-500 transition-colors'
                                                icon={faTrashAlt}
                                            />
                                        </>
                                    ) : (
                                        <p className='max-w-full text-ellipsis overflow-hidden'>{option.name}</p>
                                    )}
                                </div>
                            ))}
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default DropdownInput;