import {useTranslation} from "react-i18next";
import Tooltip from "../Shared/Tooltip";

export default function DataGridNoRowsOverlay() {
    const { t } = useTranslation()
    const activeFilters = localStorage.getItem('activeFilters');
    let isFiltered = false;

    if (activeFilters !== null) {
        try {
            const parsedFilters = JSON.parse(activeFilters);

            if (Array.isArray(parsedFilters) && parsedFilters.length > 0) {
                isFiltered = parsedFilters.some(filter => filter.value !== "");
            }
        } catch (e) {
            isFiltered = false;
        }
    }

    return (
        <div className='rounded-[0.4rem] border-[1px] border-[rgba(224, 224, 224, 1)] mb-[100px]'>
            <div className='h-[64px] rounded-t-[0.4rem] bg-white border-b-[1px] border-[rgba(224, 224, 224, 1)]'></div>
            <div className={'bg-[#f9fbfd] rounded-b-[0.4rem] flex items-center justify-center h-[200px] text-xl gap-2'}>{t('datagrid:noData')}<Tooltip text={isFiltered ? t('datagrid:noCardsFiltered') : t('datagrid:noCards')}/></div>
        </div>
    );
}
