import React, { useState, useEffect, useRef } from 'react';
import info from "../../../../../Assets/info.svg";
import { EditorProps } from '../types';
import Tooltip from './Tooltip';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/pro-solid-svg-icons";

interface SystemField {
  title: string;
  value: string;
}

const Editor: React.FC<EditorProps> = ({ csvData, setCsvData, foundErrors, remainingErrors, updateRemainingErrors }: EditorProps) => {
  const [tooltipVisible, setTooltipVisible] = useState<{ [key: string]: boolean }>({});
  const [editedCells, setEditedCells] = useState<{ [key: string]: boolean }>({});
  const [currentErrorIndex, setCurrentErrorIndex] = useState<number>(0);
  const errorRefs = useRef<Record<string, HTMLTableCellElement | null>>({});
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});
  const { t } = useTranslation();
  const [allDateClicked, setAllDateClicked] = useState<boolean>(false);

  const systemFields: SystemField[] = [
    { title: t("mapping:initials/firstname"), value: "Initialen/Voornaam"},
    { title: t("mapping:middle/lastname"), value: "Tussenvoegsel+achternaam"},
    { title: t("mapping:email"), value: "E-mailadres(optioneel)" },
    { title: t("mapping:number"), value: "Nummer"},
    { title: t("mapping:validFrom"), value: "Geldig van"},
    { title: t("mapping:validUntil"), value: "Geldig tot(optioneel)" },
    { title: t("mapping:types"), value: "Type(s) (Erelid, Senior, Team etc. Meerdere types scheiden met |)" },
    { title: t("mapping:dateOfBirth"), value: "Geboortedatum (optioneel)" },
    { title: t("mapping:location"), value: "Locatie (optioneel)" },
    { title: t("mapping:phoneNumber"), value: "Telefoonnummer (optioneel)" },
    { title: t("mapping:department"), value: "Afdeling (optioneel)" },
    { title: t("mapping:numberOfPersons"), value: "Aantal personen (optioneel)" },
    { title: t("mapping:countryCode"), value: "Landcode(NL, DE, FR, EN, ES)" },
    { title: t("mapping:discountCode"), value: "kortingscode (optioneel)" },
    { title: t("mapping:team"), value: "Team (optioneel)" },
  ];

  const handleEditCell = (rowIndex: number, colIndex: number, value: string) => {
    const updatedData = csvData.map((row, index) => {
      if (index === rowIndex + 1) {
        const updatedRow = [...row];
        updatedRow[colIndex] = value;
        return updatedRow;
      }
      return row;
    });
    setCsvData(updatedData);

    const cellKey = `${rowIndex}-${colIndex}`;
    const isAlreadyEdited = Boolean(editedCells[cellKey]);
    setEditedCells((prev) => ({ ...prev, [cellKey]: true }));

    const errorIndex = foundErrors.findIndex((foundError) => {
      return (
        Number(foundError.row) === rowIndex + 1 &&
        Number(foundError.column) === colIndex + 1
      );
    });
    if (errorIndex !== -1 && !isAlreadyEdited) {
      updateRemainingErrors(remainingErrors - 1);
    }
  };


  const isHighlighted = (rowIndex: number, colIndex: number) => {
    const errorRowColumn = foundErrors.some(
      (foundError) => foundError.row == rowIndex && foundError.column == colIndex
    );
    return errorRowColumn;
  };

  const getErrorMessage = (rowIndex: number, colIndex: number) => {
    const foundError = foundErrors.find(
      (foundError) => foundError.row == rowIndex + 1 && foundError.column == colIndex + 1
    );
    return foundError ? `${foundError.issue}` : '';
  };

  const showTooltip = (key: string) => {
    setTooltipVisible((prev) => ({ ...prev, [key]: true }));
  };

  const hideTooltip = (key: string) => {
    setTooltipVisible((prev) => ({ ...prev, [key]: false }));
  };

  const goToNextError = () => {
    if (foundErrors.length > 0) {
      let nextIndex = (currentErrorIndex + 1) % foundErrors.length;
  
      for (let i = 0; i < foundErrors.length; i++) {
        const error = foundErrors[nextIndex];
        const cellKey = `${error.row - 1}-${error.column - 1}`;

        if (allDateClicked && error.field === "Geldig van") {
          nextIndex = (nextIndex + 1) % foundErrors.length;
          continue;
        }
  
        if (!editedCells[cellKey]) {
          const errorRef = errorRefs.current[`${error.row - 1}-${error.column - 1}`];
          const inputRef = inputRefs.current[`${error.row - 1}-${error.column - 1}`];
  
          errorRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          inputRef?.focus();
          setCurrentErrorIndex(nextIndex);
          return;
        }
  
        nextIndex = (nextIndex + 1) % foundErrors.length;
      }
    }
  };
  

  const handleSetCurrentDate = (rowIndex: number, colIndex: number) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;

    handleEditCell(rowIndex, colIndex, formattedDate);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        goToNextError();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentErrorIndex, foundErrors, editedCells]);

  useEffect(() => {
    if (foundErrors.length > 0) {
      const firstError = foundErrors[0];
      const firstErrorRef = errorRefs.current[`${firstError.row - 1}-${firstError.column - 1}`];
      const firstInputRef = inputRefs.current[`${firstError.row - 1}-${firstError.column - 1}`];

      firstErrorRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstInputRef?.focus();
    }
  }, [foundErrors]);

  const isRowError = (rowIndex: number, colIndex: number) => {
    const foundError = foundErrors.find(
      (foundError) => foundError.row == rowIndex + 1 && foundError.column == colIndex + 1
    );
    return Boolean(
      foundError
    )
  }

  const handleSetAllCurrentDates = (colIndex: number) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`;
  
    let errorsFixed = 0;
  
    const updatedData = csvData.map((row, rowIndex) => {
      
      const cellHasError = isRowError(rowIndex, colIndex);
      if (cellHasError) {
        errorsFixed++;
      }

      if (rowIndex === 0) {
        return row;
      }

      const updatedRow = [...row];
      updatedRow[colIndex] = formattedDate;
  
      return updatedRow;
    });
    setCsvData(updatedData);
    updateRemainingErrors(remainingErrors - errorsFixed);
    setAllDateClicked(true);
  };
  
  
  return (
    <div>
      <div className="overflow-scroll h-[71.5vh]">
        {csvData.length > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  {systemFields.map((systemField, index) => (
                    <th key={index} className={`text-left p-2 max-w-[200px] whitespace-pre-wrap break-words`}>
                      {index === 4 && !allDateClicked ? (
                        <div className='flex gap-2 relative'>
                          <div
                            className="cursor-pointer"
                            onMouseEnter={() => showTooltip('headerDate')}
                            onMouseLeave={() => hideTooltip('headerDate')}
                            onClick={() => handleSetAllCurrentDates(index)}
                          >
                            <FontAwesomeIcon icon={faCalendarDays} />
                          </div>
                          {tooltipVisible['headerDate'] && (
                            <Tooltip bottom message={t("mapping:tooltipSetAllToDate")} className={`bg-map-blue`} />
                          )}
                          <p>{systemField.title}</p>
                        </div>
                      ) : (<p>{systemField.title}</p>)}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {csvData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((col, colIndex) => {
                      const tooltipKey = `${rowIndex}-${colIndex}-Date`;
                      const tooltipDateKey = `${rowIndex}-${colIndex}`;
                      const errorMessage = getErrorMessage(rowIndex, colIndex);
                      const cellKey = `${rowIndex}-${colIndex}`;
                      const isEdited = editedCells[cellKey];

                      return (
                        <td
                          key={colIndex}
                          ref={(el) => (errorRefs.current[`${rowIndex}-${colIndex}`] = el)}
                          className="p-2 relative"
                        >
                          <div className="relative w-full">
                            {errorMessage && !isEdited && colIndex === 4 && !allDateClicked && (
                              <>
                                <div
                                  className="absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                  onMouseEnter={() => showTooltip(tooltipDateKey)}
                                  onMouseLeave={() => hideTooltip(tooltipDateKey)}
                                  onClick={() => handleSetCurrentDate(rowIndex, colIndex)}
                                >
                                  {colIndex === 4 && getErrorMessage(rowIndex, colIndex) ? <FontAwesomeIcon className='text-white' icon={faCalendarDays} /> : null}
                                </div>
                                {tooltipVisible[tooltipDateKey] && (
                                  <Tooltip message={t("mapping:tooltipSetToDate")} className={`bg-red-600`} style={{ left: '18px' }} />
                                )}
                              </>
                            )}
                            <input
                              type="text"
                              ref={(el) => (inputRefs.current[`${rowIndex}-${colIndex}`] = el)}
                              className={`p-2 w-[200px] rounded-[0.4rem]
                                ${errorMessage && !isEdited && getErrorMessage(rowIndex, colIndex) ? `pr-8` : null}
                                ${errorMessage && !isEdited && colIndex === 4 && !allDateClicked && getErrorMessage(rowIndex, colIndex) ? `pl-[30px]` : null}
                                ${isEdited || colIndex === 4 && allDateClicked
                                  ? 'bg-blue-200'
                                  : isHighlighted(rowIndex + 1, colIndex + 1)
                                    ? 'text-white'
                                    : 'bg-[#F5F5F5]'
                                }
                                ${(errorMessage && !isEdited && !(colIndex === 0 || colIndex === 1 || colIndex === 3 || colIndex === 4) && getErrorMessage(rowIndex, colIndex)) ? `bg-orange-500` : null}
                                ${(errorMessage && !isEdited && (colIndex === 0 || colIndex === 1 || colIndex === 3 || colIndex === 4 && !allDateClicked) && getErrorMessage(rowIndex, colIndex)) ? `bg-red-500` : null}
                                `}
                              value={col}
                              onChange={(e) =>
                                handleEditCell(rowIndex, colIndex, e.target.value)
                              }
                            />
                            {errorMessage && !isEdited && !(colIndex === 4 && allDateClicked) && (
                              <>
                                <div
                                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                  onMouseEnter={() => showTooltip(tooltipKey)}
                                  onMouseLeave={() => hideTooltip(tooltipKey)}
                                >
                                  <img
                                    src={info}
                                    className='bg-white rounded-full'
                                    alt="details"
                                  />
                                </div>
                                {tooltipVisible[tooltipKey] && (
                                  <Tooltip message={errorMessage} className={`
                                  ${(errorMessage && !isEdited && !(colIndex === 0 || colIndex === 1 || colIndex === 3 || colIndex === 4) && getErrorMessage(rowIndex, colIndex)) ? `bg-orange-600` : null}
                                  ${(errorMessage && !isEdited && (colIndex === 0 || colIndex === 1 || colIndex === 3 || colIndex === 4) && getErrorMessage(rowIndex, colIndex)) ? `bg-red-600` : null}
                                  `} style={{ right: '18px' }} />
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default Editor;
