import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DateRangePicker } from 'react-date-range';
import { nlBE } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { AnimatePresence, motion } from 'framer-motion';
import { faThumbTack, faThumbTackSlash } from '@fortawesome/pro-solid-svg-icons';

interface GraphFilterProps {
    icon?: IconDefinition;
    onDatesChange?: (dates: { fromDate: Date; toDate: Date }) => void;
}

const GraphFilter = ({ icon, onDatesChange }: GraphFilterProps) => {
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [selected, setSelected] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
        }
    ]);

    const [open, setOpen] = useState<boolean>(false);
    const refOne = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const [showLastTwoWeeks, setShowLastTwoWeeks] = useState(
        () => localStorage.getItem('showLastTwoWeeks') === 'true'
    );

    useEffect(() => {
        localStorage.setItem('showLastTwoWeeks', showLastTwoWeeks.toString());
    }, [showLastTwoWeeks]);

    useEffect(() => {
        // Get the dates from storage
        const savedDates = sessionStorage.getItem('selectedDates');
        const savedShowLastTwoWeeks = localStorage.getItem('showLastTwoWeeks');

        if (savedDates) {
            const parsedDates = JSON.parse(savedDates);
            const fromDate = new Date(parsedDates.fromDate);
            const toDate = new Date(parsedDates.toDate);

            setRange([{ startDate: fromDate, endDate: toDate, key: 'selection' }]);
            setSelected([{ startDate: fromDate, endDate: toDate }]);

            if (onDatesChange) {
                onDatesChange({ fromDate, toDate });
            }
        } else if (savedShowLastTwoWeeks === 'true' && !savedDates) {
            const today = new Date();
            const lastTwoWeeksStart = new Date(today);
            lastTwoWeeksStart.setDate(today.getDate() - 13);

            setRange([{ startDate: lastTwoWeeksStart, endDate: today, key: 'selection' }]);
            setSelected([{ startDate: lastTwoWeeksStart, endDate: today }]);

            if (onDatesChange) {
                onDatesChange({ fromDate: lastTwoWeeksStart, toDate: today });
            }
        } else {
            if (onDatesChange) {
                onDatesChange({ fromDate: new Date(), toDate: new Date() });
            }
        }

        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
        return () => {
            document.removeEventListener("keydown", hideOnEscape, true);
            document.removeEventListener("click", hideOnClickOutside, true);
        };
    }, []);

    const formatDateToYMD = (date: Date, number: number): string => {
        date.setHours(number);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const hideOnEscape = (e: any) => {
        if (e.key === "Escape") {
            setOpen(false);
        }
    };

    const hideOnClickOutside = (e: any) => {
        //@ts-ignore
        if (refOne.current && !refOne.current.contains(e.target) && inputRef.current && !inputRef.current.contains(e.target)) {
            setOpen(false);
        }
    };

    const handleDateChange = (ranges: any) => {
        setRange([ranges.selection]);
    };

    const confirm = () => {
        const fromDate = new Date(range[0].startDate);
        const toDate = new Date(range[0].endDate);

        if (onDatesChange) {
            onDatesChange({ fromDate, toDate });
        }

        setOpen(false);

        setSelected([
            {
                startDate: fromDate,
                endDate: toDate,
            }
        ]);

        // Save dates to session storage
        sessionStorage.setItem('selectedDates', JSON.stringify({
            fromDate: fromDate.toISOString(),
            toDate: toDate.toISOString()
        }));
    };

    const isSelectedToday = (date: Date) => {
        if (!(date instanceof Date)) {
            return false;
        }
        const today = new Date();
        return (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        );
    };


    const toggleFilter = () => {
        setOpen(!open);
    };



    return (
        <>
            <div className={`z-[3]`} style={{ position: 'relative', width: '100%' }}>
                <div className=' justify-end flex flex-row'>
                    <div>
                        <div className=' flex justify-end'>
                            {icon && (
                                <div className="items-center flex">
                                    <FontAwesomeIcon className={`text-lg font-semibold font-title mr-4 transition duration-200 ease-in-out ${open ? 'text-[#0175FF]' : 'text-[#333]'}`} style={{ fontSize: '1.2rem' }} icon={icon} />
                                </div>
                            )}
                            <div
                                ref={inputRef}
                                className={`px-4 py-2 flex focus:outline-none cursor-pointer rounded-[0.4rem] transition duration-200 ease-in-out`}
                                style={{
                                    border: open ? '1px solid #0175FF' : '1px solid #D7E3F2',
                                    fontFamily: 'Inter',
                                    backgroundColor: open ? '#F0F9FF' : '#FFFFFF',
                                    color: open ? '#0175FF' : '#333'
                                }}
                                onClick={toggleFilter}
                            >
                                {formatDateToYMD(selected[0].startDate, 3)} {t('overview:till')} {formatDateToYMD(selected[0].endDate, 3)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative'>
                <AnimatePresence>
                    {open && (
                        <div className='z-[101] absolute right-0 left-[calc(100% - 558px)] top-1' ref={refOne}>
                            <motion.div
                                initial={{ height: 0 }}
                                animate={{ height: 'auto' }}
                                exit={{ height: 0 }}
                                transition={{ duration: 0.3 }}
                                className='relative border-gray-200 border-2 bg-white overflow-hidden rounded-[0.4rem]'>
                                <div className='ml-[-226px] sm:ml-0'>
                                    <DateRangePicker
                                        onChange={handleDateChange}
                                        editableDateInputs={false}
                                        moveRangeOnFirstSelection={false}
                                        showSelectionPreview={true}
                                        months={1}
                                        ranges={range}
                                        locale={nlBE}
                                        direction="horizontal"
                                        staticRanges={[
                                            {
                                                label: t('overview:Today'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => ({ startDate: new Date(), endDate: new Date() }),
                                            },
                                            {
                                                label: t('overview:Yesterday'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const yesterday = new Date();
                                                    yesterday.setDate(yesterday.getDate() - 1);
                                                    return { startDate: yesterday, endDate: yesterday };
                                                },
                                            },
                                            {
                                                label: t('overview:ThisWeek'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const today = new Date();
                                                    const startOfWeek = new Date(today);
                                                    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + (startOfWeek.getDay() === 0 ? -6 : 1));
                                                    const endOfWeek = new Date(today);
                                                    endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 7);
                                                    return { startDate: startOfWeek, endDate: endOfWeek };
                                                },
                                            },
                                            {
                                                label: t('overview:LastWeek'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const today = new Date();
                                                    const lastWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 6);
                                                    const lastWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
                                                    return { startDate: lastWeekStart, endDate: lastWeekEnd };
                                                },
                                            },
                                            {
                                                label: t('overview:ThisMonth'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const today = new Date();
                                                    const firstDayOfThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                                                    const lastDayOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                                                    return { startDate: firstDayOfThisMonth, endDate: lastDayOfThisMonth };
                                                },
                                            },
                                            {
                                                label: t('overview:LastMonth'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const today = new Date();
                                                    const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                                                    const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                                                    return { startDate: firstDayOfPreviousMonth, endDate: lastDayOfPreviousMonth };
                                                },
                                            },
                                            {
                                                label: t('overview:ThisYear'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const today = new Date();
                                                    const thisYearStartDate = new Date(today.getFullYear(), 0, 1);
                                                    const thisYearEndDate = new Date(today.getFullYear(), 11, 31);
                                                    return { startDate: thisYearStartDate, endDate: thisYearEndDate };
                                                },
                                            },
                                            {
                                                label: t('overview:LastYear'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const today = new Date();
                                                    const lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1);
                                                    const lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31);
                                                    return { startDate: lastYearStartDate, endDate: lastYearEndDate };
                                                },
                                            },
                                            {
                                                label: t('overview:LastTwoWeeks'),
                                                isSelected: (date: Date) => isSelectedToday(date),
                                                range: () => {
                                                    const today = new Date();
                                                    const lastTwoWeeksStart = new Date(today);
                                                    lastTwoWeeksStart.setDate(today.getDate() - 13);
                                                    return { startDate: lastTwoWeeksStart, endDate: today };
                                                },
                                            }
                                        ]}
                                    />
                                    <div className='absolute hidden sm:flex top-[312px] left-[200px] w-6 h-[39px] items-center justify-center'>
                                        <div className='relative group flex items-center justify-center'>
                                            <FontAwesomeIcon onClick={() => setShowLastTwoWeeks(!showLastTwoWeeks)} className={`hover:text-[#0175FF] opacity-40 hover:opacity-100 cursor-pointer ${showLastTwoWeeks ? '' : ''}`} icon={showLastTwoWeeks ? faThumbTackSlash : faThumbTack} />
                                            <div className='absolute bottom-full mb-2 hidden group-hover:flex flex-col items-center'>
                                                <span className='relative z-10 p-2 text-xs text-white w-[400px] whitespace-no-wrap bg-black shadow-lg rounded-md'>
                                                    {showLastTwoWeeks ? t('overview:UnpinLastTwoWeeks') : t('overview:PinLastTwoWeeks')}
                                                </span>
                                                <div className='w-3 h-3 -mt-2 rotate-45 bg-black'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-end border-t-[2px] border-[#f7f8fb]'>
                                        <button className='rounded-[0.4rem] text-center font-bold pointer-events-auto text-white font-[16px] bg-[#0175FF] p-2 m-2' onClick={confirm}>
                                            {t('overview:adjust')}
                                        </button>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    )}
                </AnimatePresence>
            </div>
        </>
    );
};

export default GraphFilter;
