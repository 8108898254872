import { motion } from 'framer-motion';
import React, { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { Strings } from '../../../Strings/nl';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

export function FailedRequirements(obj: any, ...compare: Array<string>): boolean {
    for (let c of compare) {
        if (!obj[c]) return true;
    }
    return false;
}

export interface InputProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label: string,
    sublabel?: string,
    id?: string,
    type?: HTMLInputTypeAttribute,
    initial?: string,
    required?: boolean,
    large?: boolean,
    value?: string,
    placeholder?: string,
    valueChange?: (value: string, valid: boolean) => void,
    invalidator?: (value: string) => false | string,
    submitted?: boolean
}

const Input = (props: InputProps) => {
    const { label, type, required, value: inValue, className, initial, placeholder, large, valueChange, sublabel, invalidator, id, submitted, ...restProps } = props;

    const labelOrId = id || label;
    const { t } = useTranslation();

    const [value, setValue] = useState(initial || '');
    const [isNotValid, setIsNotValid] = useState<boolean | string>(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => void (inValue != undefined && setValue(inValue)), [inValue]);

    const defaultInValidator = () => false;

    const removeNonNumeric = (input: string): string => {
        return input.replace(/\D/g, '');
    };

    const onChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let value = evt.currentTarget.value;

        if (type === 'number') {
            value = removeNonNumeric(value);
        }

        let invalidatorReturn = (invalidator || defaultInValidator)(value);

        let validity: string | undefined;
        let number = '';

        if (required && value.length === 0) {
            if (type === 'number') {
                validity = t('onlyNumbersAllowed') || undefined;
            } else {
                validity = t('noInput') || undefined;
            }

        } else if (type === 'url' && value.length > 0 && !value.startsWith('https://') && !value.startsWith('http://')) {
            validity = t('modal:urlError') as string;

        } else if (type === 'email' && value.length > 0) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                validity = t('missingDomain') as string;
            }

        } else {
            if (typeof invalidatorReturn === 'string' && invalidatorReturn.includes('langer')) {
                number = removeNonNumeric(invalidatorReturn);
                validity = t('minLength', { min: number }) || undefined;
            } else if (typeof invalidatorReturn === 'string' && invalidatorReturn.includes('kleiner')) {
                number = removeNonNumeric(invalidatorReturn);
                validity = t('maxLength', { max: number }) || undefined;
            } else {
                invalidatorReturn = (invalidator || defaultInValidator)(value);
            }
        }

        setIsNotValid(validity || false);

        setValue(value);

        if (valueChange) {
            valueChange(value, !validity && (required ? value.length > 0 : true));
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <div {...restProps} className={`flex flex-col mb-6 ${className}`}>
            <label htmlFor={labelOrId}>
                {label}{required && <span className='text-red-400'>*</span>} {sublabel && <span className='opacity-70 text-sm'>({sublabel})</span>}
            </label>
            <div className={`flex flex-grow mt-1 rounded-[0.4rem] border-[1px] ${submitted && !value || submitted && isNotValid ? 'border-red-500' : 'border-[#D2DCEA]'}`}>
                {!large && (
                    <div className="relative flex-grow">
                        <input
                            placeholder={placeholder}
                            className="flex flex-grow bg-transparent p-2 px-4 rounded-[0.4rem] w-full"
                            value={value}
                            onChange={onChange}
                            type={type === 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
                            name={labelOrId}
                            id={labelOrId}
                        />
                        {type === 'password' && (
                            <button
                                type="button"
                                className={`${showPassword ? 'right-[11px]' : 'right-[12.5px]'} absolute  top-1/2 transform -translate-y-1/2 text-gray-600 focus:outline-none`}
                                onClick={togglePasswordVisibility}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                        )}
                    </div>
                )}
                {large && (
                    <textarea
                        placeholder={placeholder}
                        className="flex flex-grow bg-transparent p-1 min-h-[8rem] rounded-[0.4rem]"
                        value={value}
                        onChange={onChange}
                        name={labelOrId}
                        id={labelOrId}
                    />
                )}
            </div>
            <input name={'@VALID@' + labelOrId} type="hidden" value={!isNotValid + ''} />
            {(invalidator || required) && (
                <motion.div animate={!isNotValid ? { height: '0rem' } : { height: '1.2rem' }} className='text-sm overflow-hidden text-red-400 mb-2'>
                    {isNotValid || '\u00a0'}
                </motion.div>
            )}
        </div>
    );
};

export default Input;
