import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOrganisationsQuery, useLazyGetOrganisationSettingsQuery } from '../../../../Redux/Api/Organisations';
import { useCreateUserMutation } from '../../../../Redux/Api/Users';
import { useAppSelector } from '../../../../Redux/store';
import { JWT } from '../../../../Utils/JWT';
import { useTranslation } from "react-i18next";
import { faPlus, faQrcode, faUserCrown, faUsers, faUserTie } from '@fortawesome/pro-solid-svg-icons';

import BusyArea from '../../../Shared/BusyArea';
import Button from '../../../Shared/Form/Button';
import Form from '../../../Shared/Form/Form';
import Input, { FailedRequirements } from '../../../Shared/Form/Input';
import Title from '../../../Shared/Title';
import LoadingPage from '../../LoadingPage';
import StatusPopups from '../../../Shared/Form/StatusPopups';
import { min } from '../../../../Utils/InValidation';
import Card from '../../../Shared/Card/Card';
import CardGrid from '../../../Shared/Card/CardGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UsersCreatePage = () => {
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const org_id_ifadmin = localStorage.getItem('active_organisation')!;
    const hidden = { hidden: 'false' };
    const [getOrganisationsSettings, { data: settings }] = useLazyGetOrganisationSettingsQuery();
    const [notificationAccess, setNotificationAccess] = useState(false);
    const [sponsorAccess, setSponsorAccess] = useState(false);
    const [patrickAssistantAccess, setPatrickAssistantAccess] = useState(false);

    useEffect(() => {
        if (org_id) getOrganisationsSettings(org_id);
    }, [org_id, getOrganisationsSettings]);

    useEffect(() => {
        if (settings) {
            setNotificationAccess(!!settings.notification)
            setSponsorAccess(!!settings.sponsors)
            setPatrickAssistantAccess(!!settings.patrickAI)
        }
    }, [settings]);

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [createUser] = useCreateUserMutation();
    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [role, setRole] = useState('ROLE_USER');

    const isAdmin = JWT.read<Array<string>>('roles')?.includes('ROLE_SUPER_ADMIN');
    const roles = JWT.read<Array<string>>('roles') || [];

    const submit = async (data: {
        email: string,
        firstName: string,
        lastName: string,
        password: string,
        organisationId: string,
        role: string
    }) => {
        if (FailedRequirements(data, 'firstName', 'lastName', 'password', 'role', 'organisationId')) {
            return setError(t('modal:missing') as string);
        }

        if (busy) return;

        setBusy(true);
        setError('');

        if (!isAdmin) {
            data.organisationId = org_id;
        }

        if (['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SOCIAL'].includes(role)) {
            data.role = role;
        } else {
            setError(t('user:list:invalidRole') as string);
            setBusy(false);
            return;
        }

        try {
            await createUser(data as any).unwrap();
            sessionStorage.setItem('successMessage', t('user:list:userAdded') as string);
            navigate(`/${i18n.language}/dashboard/users/list`);
        } catch (e) {
            let err = e as { status?: number; 'hydra:description'?: string };

            if (err.status === 409) {
                setError(t('user:list:emailExists') as string);
            } else {
                setError(err['hydra:description'] || t('user:list:genericError') as string);
            }
            setBusy(false);
        }

        setTimeout(() => {
            setBusy(false);
        }, 200);
    };

    const buildExtraFeatures = () => {
        let extraFeatures = '';
        if (notificationAccess) extraFeatures += t('user:list:extraFeaturesNotification') + ', ';
        if (sponsorAccess) extraFeatures += t('user:list:extraFeaturesSponsor') + ', ';
        if (patrickAssistantAccess) extraFeatures += t('user:list:extraFeaturesPatrick') + ', ';
        return extraFeatures
    }

    if (!org_id || !settings) return <LoadingPage />;

    return (
        <>
            <Title text={t('user:list:userAdd')} textNotBold />
            <BusyArea busy={busy}>
                <Form submit={submit}>
                    <StatusPopups type="error" text={error} />
                    <Input
                        submitted={error ? true : false}
                        required
                        label={t('user:list:email')}
                        type="email"
                        id="email"
                    />
                    <Input
                        submitted={error ? true : false}
                        required
                        label={t('user:list:firstname')}
                        id="firstName"
                    />
                    <Input
                        submitted={error ? true : false}
                        required
                        label={t('user:list:lastname')}
                        id="lastName"
                    />
                    <Input
                        submitted={error ? true : false}
                        required
                        type="password"
                        invalidator={min(8)}
                        label={t('user:list:password')}
                        id="password"
                    />

                    <input
                        type="hidden"
                        value={org_id && !roles.includes('ROLE_SUPER_ADMIN') ? org_id : org_id_ifadmin}
                        name="organisationId"
                    />

                    {roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_ADMIN') ? (
                        <>
                            <h1 className='-mb-4'>{t('user:list:role')}<span className='text-red-400'>*</span></h1>
                            <CardGrid cols={3}>
                                <Card
                                    icon={faUserTie}
                                    hideOverflow
                                    className={`transition duration-300 ease-in-out ${role === 'ROLE_ADMIN'
                                        ? 'bg-blue-100'
                                        : 'bg-light-200 hover:bg-gray-200'
                                        }`}
                                    onClick={() => setRole('ROLE_ADMIN')}
                                    outline
                                    rounded>
                                    <label className='cursor-pointer flex justify-between pr-3 mb-3 z-[2]'>
                                        <div>
                                            <input
                                                className='mr-2 accent-blue-500'
                                                type="radio"
                                                name="role"
                                                value="ROLE_ADMIN"
                                                checked={role === 'ROLE_ADMIN'}
                                            />
                                            {t('user:list:adminRole')}
                                        </div>
                                        <FontAwesomeIcon icon={faUserTie} className={`ml-2 transition ${role === 'ROLE_ADMIN' ? 'opacity-75' : 'opacity-25'}`} />
                                    </label>
                                    <p className="ml-1 text-sm text-gray-600 z-[2]">{t('user:list:adminRoleDescription')}</p>
                                </Card>
                                <Card
                                    icon={faQrcode}
                                    hideOverflow
                                    className={`transition duration-300 ease-in-out ${role === 'ROLE_USER'
                                        ? 'bg-blue-100'
                                        : 'bg-light-200 hover:bg-gray-200'
                                        }`}
                                    onClick={() => setRole('ROLE_USER')}
                                    outline
                                    rounded>
                                    <label className='cursor-pointer flex justify-between pr-3 mb-3 z-[2]'>
                                        <div>
                                            <input
                                                className='mr-2 accent-blue-500'
                                                type="radio"
                                                name="role"
                                                value="ROLE_USER"
                                                checked={role === 'ROLE_USER'}
                                            />
                                            {t('user:list:userRole')}
                                        </div>
                                        <FontAwesomeIcon icon={faQrcode} className={`ml-2 transition ${role === 'ROLE_USER' ? 'opacity-75' : 'opacity-25'}`} />
                                    </label>
                                    <p className="ml-1 text-sm text-gray-600 z-[2]">{t('user:list:userRoleDescription')}</p>
                                </Card>
                                <Card
                                    icon={faUsers}
                                    hideOverflow
                                    className={`transition duration-300 ease-in-out ${role === 'ROLE_SOCIAL'
                                        ? 'bg-blue-100'
                                        : 'bg-light-200 hover:bg-gray-200'
                                        }`}
                                    onClick={() => setRole('ROLE_SOCIAL')}
                                    outline
                                    rounded>
                                    <label className='cursor-pointer flex justify-between pr-3 mb-3 z-[2]'>
                                        <div>
                                            <input
                                                className="mr-2 accent-blue-500"
                                                type="radio"
                                                name="role"
                                                value="ROLE_SOCIAL"
                                                checked={role === 'ROLE_SOCIAL'}
                                            />
                                            {t('user:list:socialUserRole')}
                                        </div>
                                        <FontAwesomeIcon icon={faUsers} className={`ml-2 transition ${role === 'ROLE_SOCIAL' ? 'opacity-75' : 'opacity-25'}`} />
                                    </label>
                                    <p className="ml-1 text-sm text-gray-600 z-[2]">{t('user:list:socialUserRoleDescription', { extraFeatures: buildExtraFeatures() })}</p>
                                </Card>
                            </CardGrid>
                        </>
                    ) : (
                        <input type="hidden" value="ROLE_USER" name="role" />
                    )}

                    <Button icon={faPlus} iconright title={t('user:list:add')} />
                </Form>
            </BusyArea>
        </>
    );
};

export default UsersCreatePage;
