import { faPaperPlane, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import React, { useContext, useState, useEffect } from 'react'
import { useLazyGetOrganisationTypesQuery, usePostNotificationMutation } from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store'
import { Hydra } from '../../../../Utils/Hydra'
import { all, max, min } from '../../../../Utils/InValidation'
import BusyArea from '../../../Shared/BusyArea'
import Button from '../../../Shared/Form/Button'
import Form from '../../../Shared/Form/Form'
import Input, { FailedRequirements } from '../../../Shared/Form/Input'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Title from '../../../Shared/Title'
import Dropdown from "../../../Shared/Form/Dropdown";
import LoadingPage from "../../LoadingPage";
import { useTranslation } from "react-i18next";
import NotifyPreview from "./NotifyPreview";
import { useNavigate } from 'react-router-dom';


const NotifyPage = () => {

    const modalContext = useContext(ModalContext)
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const org_id = useAppSelector((s) => s.organisation.id)!
    const [getTypes, { data: types }] = useLazyGetOrganisationTypesQuery()

    const [error, setError] = useState('')
    const [busy, setBusy] = useState(false)
    const [sendNotifications] = usePostNotificationMutation()
    const [message, setMessage] = useState('')
    const [sendType, setSendType] = useState(true)
    const [refetch, setRefetch] = useState(false)

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const { withModal } = useContext(ModalContext);

    useEffect(() => {
        if (org_id)
            getTypes(org_id)
    }, [org_id])

    const handleSendType = () => {
        // @ts-ignore
        if (types.length > 0) {
            setSendType(!sendType)
        } else {
            setError(t('modal:noTypes') as string)
        }
    }

    const submit = async (data: { title: string, content: string, url: string, cardType: string }) => {
        setError('')
        if (busy) return
        if (FailedRequirements(data, 'title', 'content')) return setError(t('modal:error') as string)

        if (data.url !== "") {
            if (!data.url.toLowerCase().includes('http')) {
                setError(t('modal:urlError') as string)
                setBusy(false)
                return;
            }
        }

        if (!(await modalContext.show(t('modal:attention') as string, t('modal:notification') as string))) {
            modalContext.hide()
            return
        }

        modalContext.hide()

        setBusy(true)
        setError('')
        setMessage('')

        try {
            await sendNotifications({
                id: org_id,
                title: data.title,
                body: data.content,
                url: data.url,
                type: data.cardType
            })

            if (data.cardType === 'all') {
                const sentMessage = t('notification:sent');
                setMessage(sentMessage);
            } else {
                const sentMessage = t('notification:sentType');
                setMessage(sentMessage);
            }

            setTitle('');
            setContent('');
            setRefetch(!refetch);

        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }

        setBusy(false)
    }

    if (!types) return <LoadingPage />

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col xl:flex-row'>
                    <div className='w-full'>
                        <Title text={t('notification:title')} subtitle={t('notification:subtitle') as string} />
                        <StatusPopups type='error' text={error} />
                        <StatusPopups type='success' text={message} />
                        <Form submit={submit}>
                            <Input
                                required
                                submitted={error ? true : false}
                                invalidator={all(min(3), max(100))}
                                label={t('notification:tableTitle')}
                                type='switch'
                                id='title'
                                onChange={(e) => setTitle((e.target as HTMLInputElement).value)}
                            />
                            <input type='checkbox' className='ml-1 h-4 w-4 mb-6' title='test' checked={sendType} onChange={handleSendType} />
                            <span className='ml-2 opacity-70 text-xl'>{t('notification:checkbox')}</span>
                            {sendType ? (
                                <input type='hidden' value='all' name='cardType' />
                            ) : (
                                <Dropdown label={t('notification:type')} id='cardType' options={
                                    Object.fromEntries(types.map(type => ([type.id, type.name])))
                                } />
                            )}
                            <Input
                                required
                                submitted={error ? true : false}
                                large
                                invalidator={all(min(3), max(250))}
                                label={t('notification:message')}
                                id='content'
                                onChange={(e) => setContent((e.target as HTMLInputElement).value)}
                            />
                            <Input
                                invalidator={all(min(3), max(250))}
                                label={t('notification:url')}
                                sublabel={t('notification:optional') as string}
                                type='url'
                                placeholder='https://'
                                id='url' />
                            <div className='flex justify-between flex-col sm:flex-row gap-4'>
                                <Button title={t('notification:send')} iconright icon={faPaperPlane} />
                                <Button
                                    title={t('notification:history')}
                                    className={title || content ? 'opacity-75' : ''}
                                    iconright
                                    icon={faArrowRight}
                                    nosubmit
                                    action={
                                        title || content
                                            ? withModal(
                                                { title: t('notification:goToHistory'), body: t('notification:goToHistoryBody') as string },
                                                () => navigate('/' + i18n.language + '/dashboard/notify/history')
                                            )
                                            : () => navigate('/' + i18n.language + '/dashboard/notify/history')
                                    }
                                />
                            </div>

                        </Form>
                    </div>
                    <div className='-m-32 sm:m-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 overflow-hidden flex justify-center xl:justify-end mb-0 sm:mb-10'>
                        {/* @ts-ignore */}
                        <NotifyPreview key={refetch} title={title} content={content} />
                    </div>
                </div>
            </BusyArea>
        </>
    )
}

export default NotifyPage;
