const Tooltip: React.FC<{ message: string; style?: React.CSSProperties; className?: string; bottom?: boolean }> = ({ message, style, className, bottom }) => {
  return (
    <div
      className={`absolute text-white font-bold ${bottom ? '-bottom-14' : 'bottom-12'} rounded-[0.4rem] -ml-[5%] w-[110%] text-sm py-1 px-2 z-10 pointer-events-none ${className}`}
    >
      <div className={`absolute ${bottom ? '-top-3 rotate-180' : '-bottom-4'} w-4 h-4 ${className}`} style={{clipPath: 'polygon(50% 100%, 0 0, 100% 0)', ...style}}/>
      {message}
    </div>
  );
};

export default Tooltip;
