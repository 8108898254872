import React from "react";
import MobileApp from "../../../Shared/App/MobileApp";
import MyCard from "../../../Shared/App/MyCard";

interface SponsorPreviewProps {
    title: string;
    image: string;
}

const SponsorPreview: React.FC<SponsorPreviewProps> = ({ title, image }) => {

    return (
        <>
            <MobileApp activeItem="mycard">
                <MyCard customSponsor sponsorTitle={title} sponsorImage={image} />
            </MobileApp>
        </>
    );
};

export default SponsorPreview;
