import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useGetReleaseNotesQuery, useDeleteReleaseNoteMutation } from '../../../../Redux/Api/ReleaseNote';
import Button from '../../../Shared/Form/Button'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Header from '../../../Shared/Table/Header'
import Row from '../../../Shared/Table/Row'
import Table from '../../../Shared/Table/Table'
import Title from '../../../Shared/Title'
import LoadingPage from '../../LoadingPage'
import { Hydra } from '../../../../Utils/Hydra'
import BusyArea from '../../../Shared/BusyArea'
import { useMediaQuery } from '@mui/material';

const ReleaseNoteList = () => {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [deleteReleaseNote] = useDeleteReleaseNoteMutation()
    const { data: releaseNote, refetch } = useGetReleaseNotesQuery();

    const isSmallScreen = useMediaQuery('(min-width: 500px)');


    const deleteReleaseNoteAction = async (id: string) => {
        if (busy) return

        setBusy(true)
        setError('')
        setSuccessMessage('')

        try {
            await deleteReleaseNote(id)
            refetch();
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
        }

        setBusy(false)
    }

    const getHighestVersion = () => {
        if (!releaseNote || releaseNote.length === 0) return '';
        const versions = releaseNote.map(note => note.version);
        versions.sort((a, b) => {
            const aParts = a.split('.').map(Number);
            const bParts = b.split('.').map(Number);

            for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
                const aVal = aParts[i] || 0;
                const bVal = bParts[i] || 0;
                if (aVal > bVal) return -1;
                if (aVal < bVal) return 1;
            }
            return 0;
        });
        return versions[0];
    }

    if (!releaseNote) return <LoadingPage />

    return (
        <>
            <BusyArea busy={busy}>
                <div className='flex flex-col lg:flex-row justify-between items-start lg:items-center'>
                    <Title text={t('releasenote:Releasenotes')} textNotBold />
                    <Button
                        action={() => navigate(`/${i18n.language}/dashboard/admin/notes/create#version=${getHighestVersion()}`)}
                        className='my-6'
                        title={t('releasenote:createReleasenote')}
                        icon={faPlus}
                        iconright
                    />
                </div>
                <Table withSearch withEdit withDelete>
                    <Header>
                        <Column initialSort='up' name={t('releasenote:version')} />
                        <Column name={t('cards:list:type')} />
                        <Column hidden={!isSmallScreen} name={t('releasenote:title')} />
                    </Header>
                    <Data>
                        {releaseNote.map((note, i) => (
                            <Row
                                firstButton='editUrl'
                                lastButton='deleteAction'
                                key={i}
                                index={i}
                                editUrl={`/${i18n.language}/dashboard/admin/notes/edit/` + note.id}
                                data={
                                    !isSmallScreen ? [
                                        note.version,
                                        note.type,
                                    ] : [
                                        note.version,
                                        note.type,
                                        note.title
                                    ]}
                                deleteConfirmMessage={t('releasenote:deleteReleaseNoteRule') as string}
                                deleteAction={() => deleteReleaseNoteAction(note.id)} />
                        ))}
                    </Data>
                </Table>
            </BusyArea>
        </>
    )
}

export default ReleaseNoteList
