import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../Shared/Form/Button";
import { faDownload, faEdit, faFileExport } from '@fortawesome/pro-solid-svg-icons';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import ErrorRow from "./ErrorRow";
import { CsvData } from "../types";
import { useMapperContext } from "../Context/MapperContext";
import Confetti from 'react-confetti'
import Editor from "./Editor";
import AlertModal from "../AlertModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { AnimatePresence, motion } from 'framer-motion'
import Title from '../../../../Shared/Title'

const PAGE_SIZE = 50;

function FileError() {
  const { t } = useTranslation();
  const { errorsFound, csvData, setCsvData, handleScan, handleDownload, handleSplitDownload, handleFetch, alertMessage, alertTitle, isAlertModalOpen, handleCloseModal, setAlertMessage, setAlertTitle, handleShowAlert, handleFileUpload } = useMapperContext();
  const [isOpen, setIsOpen] = useState(false);
  const [remainingErrors, setRemainingErrors] = useState<number>(errorsFound.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfErrors, setNumberOfErrors] = useState<number>(0);
  const [numberOfWarnings, setNumberOfWarnings] = useState<number>(0);
  const totalPages = Math.ceil(errorsFound.length / PAGE_SIZE);
  const [isEditorAvailable, setIsEditorAvailable] = useState(false);
  const [downloadDialog, setDownloadDialog] = useState(false);

  const editorState = sessionStorage.getItem('openEditor');

  useEffect(() => {
    function handleOpenEditor() {
      if (editorState === 'true')
        setIsOpen(true)
    }
    handleOpenEditor();
  }, []);

  useEffect(() => {
    if (!csvData.length && !errorsFound.length) {
      handleScan()
      handleFetch()
    }
  }, []);

  useEffect(() => {
    if (csvData.length <= 1000) {
      setIsEditorAvailable(true);
    }
  }, [csvData]);



  useEffect(() => {
    let numberOfErrors = 0;
    let numberOfWarnings = 0;
    errorsFound.forEach(error => {
      if ([1, 2, 4, 5].includes(Number(error.column))) {
        numberOfErrors++;
      } else {
        numberOfWarnings++;
      }
    });
    setNumberOfErrors(numberOfErrors)
    setNumberOfWarnings(numberOfWarnings)
  }, [errorsFound])

  const handleSaveAndRetry = async () => {
    sessionStorage.removeItem('openEditor');
    setIsOpen(false);
    try {
      await handleFileUpload()
      await handleScan()
      handleFetch()
    } catch (error) {
      console.error('Error while saving and retrying:', error);
    }
  };

  const handleCancel = () => {
    sessionStorage.removeItem('openEditor');
    setIsOpen(false);
    try {
      resetRemainingErrors()
      handleFetch()
    } catch (error) {
      console.error('Error while saving and retrying:', error);
    }
  };

  const resetRemainingErrors = () => {
    setRemainingErrors(errorsFound.length);
  }

  const exportErrors = () => {
    const csvContent = convertErrorsToCSV();
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const fileName = 'upload_errors_and_warnings.csv';
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function convertErrorsToCSV() {
    const header = ['Type', 'Row', 'Column', 'Field', 'Error Message'];
    const rows = errorsFound.map(error => {
      const errorType = [1, 2, 4, 5].includes(Number(error.column)) ? 'Error' : 'Warning';
      return [
        errorType,
        error.row,
        error.column,
        error.field,
        error.issue
      ];
    });
    const csvContent = [
      header.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');
    return csvContent;
  }

  const handleOpenCsvEditor = () => {
    if (csvData) {
      if (csvData.length <= 1000) {
        setIsOpen(true)
      } else {
        setIsEditorAvailable(false);
        displayAlert()
      }
    }
  }

  const displayAlert = () => {
    setAlertMessage(t("mapping:fileTooManyEntries"))
    setAlertTitle(t("mapping:fileTooLarge"))
    handleShowAlert()
  }

  const toggleDownloadDialog = () => {
    setDownloadDialog(!downloadDialog)
  }

  const updateRemainingErrors = (newErrorsCount: number) => setRemainingErrors(newErrorsCount);

  const currentErrors = errorsFound.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const runHandleDownload = async () => {
    try {
      await handleDownload();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      toggleDownloadDialog();
    }
  }

  const runHandleSplitDownload = async () => {
    try {
      await handleSplitDownload();
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      toggleDownloadDialog();
    }
  }

  return (
    <>
      {errorsFound.length === 0 ? (<Confetti numberOfPieces={40} className="w-full h-full absolute top-0 left-0 z-[-1]" />) : ''}
      <AlertModal isOpen={isAlertModalOpen} onClose={handleCloseModal} alertMessage={alertMessage} alertTitle={alertTitle} />
      <div className="flex flex-col justify-between">
        <div className="flex flex-col 2xl:flex-row justify-between mt-10">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 justify-between 2xl:justify-start">
            <h1 className="text-[18px] font-semibold">
              {errorsFound.length === 0 ? (
                `${t("mapping:noFileError")} 🎉`
              ) : (
                <div className='flex gap-3'>
                  <span className="text-red-700">
                    {numberOfErrors} {t(`mapping:error${numberOfErrors === 1 ? "" : "s"}`)}
                  </span>
                  <span className="text-orange-300">
                    {numberOfWarnings} {t(`mapping:warning${numberOfWarnings === 1 ? "" : "s"}`)}
                  </span>
                </div>
              )}
            </h1>
            {errorsFound.length !== 0 &&
              <div className="flex items-center -ml-2 py-2 px-3 cursor-pointer hover:text-[#3273F6] gap-1 transition-all duration-400" onClick={exportErrors}>
                <FontAwesomeIcon icon={faFileExport} className="h-6" />
                <h3>{t("mapping:export")}</h3>
              </div>}
          </div>
          <div className="flex flex-col md:flex-row gap-2 justify-between 2xl:justify-end">
            {isEditorAvailable ? <Button title={t('mapping:downloadFile')} className="bg-map-green hover:bg-gray-400" icon={faDownload} action={handleDownload} /> : <Button title={t('mapping:downloadFile')} className="bg-map-green hover:bg-gray-400" icon={faDownload} action={toggleDownloadDialog} />}
            {errorsFound.length !== 0 && <Button className={`${isEditorAvailable ? '' : 'opacity-40'}`} title={t("mapping:fixWithEditor")} icon={faEdit} action={handleOpenCsvEditor} />}
          </div>
        </div>

        <div className="mt-4 max-h-[50vh] overflow-y-scroll">
          {currentErrors.map((foundError, index) => (
            <ErrorRow key={index} index={index + (currentPage - 1) * PAGE_SIZE} error={foundError} errors={errorsFound} />
          ))}
        </div>

        {errorsFound.length !== 0 &&
          <div className="pagination-controls mt-4 flex justify-center items-center space-x-4">
            <button disabled={currentPage === 1} onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} className="p-2 disabled:opacity-50">
              <FontAwesomeIcon icon={faChevronLeft} className="w-4 h-4" />
            </button>
            <span className="text-sm">
              {currentPage} / {totalPages}
            </span>
            <button disabled={currentPage === totalPages} onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} className="p-2 disabled:opacity-50">
              <FontAwesomeIcon icon={faChevronRight} className="w-4 h-4" />
            </button>
          </div>}

        {isOpen && (
          <div className="fixed inset-0 flex flex-col justify-center items-center bg-gray-800 bg-opacity-50 z-[106]">
            <div className="bg-white p-8 rounded-[0.4rem] shadow-lg max-h-[95vh] w-[90%] flex flex-col">
              <h1 className="text-[20px] font-bold mb-3">{t('mapping:editorTitle')}</h1>
              <h3 className='m-0 text-sm text-green-800 mt-[-10px]'>{t('mapping:tabToMove')}</h3>
              <div className="flex-grow overflow-auto mb-4">
                <Editor csvData={csvData} setCsvData={setCsvData} foundErrors={errorsFound} remainingErrors={remainingErrors} updateRemainingErrors={updateRemainingErrors} />
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <div className="flex flex-col md:flex-row gap-2">
                  <Button action={handleSaveAndRetry} className='bg-map-green' title={t("mapping:saveAndRetry")} />
                  <Button action={handleCancel} title={t("mapping:cancel")} />
                </div>
                <div className="text-base">
                  <p>
                    {remainingErrors > 0 ? `${t("mapping:errorsRemaining")} ${remainingErrors}/${errorsFound.length}` : `${t("mapping:noErrorsRemaining")} ✅`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {downloadDialog && (
          <AnimatePresence>
            <div className='fixed flex items-center justify-center left-0 top-0 w-screen h-screen z-[110]'>
              <motion.div
                initial={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                animate={{ scaleX: 1, scaleY: 1, opacity: 1 }}
                exit={{ scaleX: 0.5, scaleY: 0.1, opacity: 0 }}
                className='rounded-[0.4rem] flex flex-col z-[110] w-[50%] bg-light-200 '>
                <div className='flex flex-col m-20'>
                  <Title text={t("mapping:downloadTitle")} />
                  <div className='mt-4'>
                    {t("mapping:downloadText")}
                  </div>
                  <div className='flex gap-4 mt-8 justify-between'>
                    <Button title={t('cards:list:close')} action={toggleDownloadDialog} />
                    <div className='flex gap-2'>
                      <Button title={t("mapping:downloadWhole")} action={runHandleDownload} />
                      <Button title={t("mapping:downloadSplit")} action={runHandleSplitDownload} />
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                key="background-div"
                onClick={() => setDownloadDialog(false)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.3 }}
                exit={{ opacity: 0 }}
                className='fixed left-0 top-0 w-screen h-screen bg-black' />
            </div>
          </AnimatePresence>
        )}

      </div>
    </>
  );
}

export default FileError;
