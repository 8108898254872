import { useTranslation } from "react-i18next";
import { useGetNotificationsQuery } from '../../../../Redux/Api/Organisations';
import { useAppSelector } from '../../../../Redux/store';
import { DeviceFrameset } from 'react-device-frameset';
import IconSVG from "../../../../Assets/icon.svg?component";
import React, { useState, useEffect } from "react";
import { JWT } from '../../../../Utils/JWT';
import LoadingPage from "../../LoadingPage";
import MobileApp from "../../../Shared/App/MobileApp";

interface Notification {
    id: string;
    title: string;
    data: string;
    text: string;
    sentAt: {
        date: string;
    };
}

interface NotifyHistoryProps {
    title: string;
    content: string;
}

const NotifyHistory: React.FC<NotifyHistoryProps> = ({ title, content }) => {
    const { t, i18n } = useTranslation();
    const org_id = useAppSelector((s) => s.organisation.id)!;
    const { data = [], isFetching, refetch } = useGetNotificationsQuery(org_id!, {
        skip: !org_id,
    }) as { data?: Notification[]; isFetching: boolean; refetch: () => void };
    const [fileBlob, setFileBlob] = useState<string>('');
    const theme = useAppSelector((s) => s.organisation.settings?.theme);
    const first_name = JWT.read<string>('given_name');

    useEffect(() => {
        if (org_id) {
            refetch();
        }
    }, [org_id, refetch]);

    useEffect(() => {
        if (!theme) return;
        setFileBlob(theme.icon);
    }, [theme]);

    if (!org_id) { return <DeviceFrameset device="iPhone X" color="black" style={{ width: "428px", height: "767px", overflow: "hidden", position: "relative" }}><LoadingPage /></DeviceFrameset>; }
    if (isFetching) { return <DeviceFrameset device="iPhone X" color="black" style={{ width: "428px", height: "767px", overflow: "hidden", position: "relative" }}><LoadingPage /></DeviceFrameset>; }
    if (!data) { return <DeviceFrameset device="iPhone X" color="black" style={{ width: "428px", height: "767px", overflow: "hidden", position: "relative" }}><LoadingPage /></DeviceFrameset>; }

    const lastNotifications = [...data]
        .sort((a, b) => new Date(b.sentAt.date).getTime() - new Date(a.sentAt.date).getTime())
        .slice(0, 5);


    return (
        <>
            <MobileApp activeItem="mycard" showDefaultPage fullOverlay>
                <div className="absolute top-0 left-0 w-full h-full pt-7 bg-black/10 overflow-hidden">
                    <p className={`text-sm text-gray-300 bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md transition-all duration-500 ${title || content ? 'mt-[10px]' : '-mt-[210px]'}`}>{t('notification:preview')}.<br /><span className="text-xs text-gray-300">{t('notification:previewDisclaimer')}.</span></p>
                    <div className={`bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md flex items-center mb-10`}>
                        <div className="bg-white p-0.5 rounded-full w-[40px] h-[40px] flex items-center justify-center scale-75">
                            <IconSVG className="text-brand w-6 h-6" />
                        </div>
                        <div className="ml-3 flex-1">
                            <div className="flex justify-between items-center">
                                <h3 className="text-base font-semibold truncate max-w-[225px] text-sm">{title}</h3>
                                <p className="text-xs text-gray-300">
                                    {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </p>
                            </div>
                            <p className="text-sm text-gray-100 whitespace-pre-line word-break custom-truncate max-w-[260px] truncate overflow-hidden">{content}</p>
                        </div>
                    </div>
                    {lastNotifications.length > 0 && (<p className="text-sm text-gray-300 bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md">{t('notification:lastNotifications')}.</p>)}
                    {lastNotifications.length > 0 && (
                        lastNotifications.map((notification: Notification) => (
                            <div key={notification.id} className="bg-black/70 p-4 m-3 text-white rounded-3xl shadow-md flex items-center">
                                <div className="bg-white p-0.5 rounded-full w-[40px] h-[40px] flex items-center justify-center scale-75">
                                    <IconSVG className="text-brand w-6 h-6" />
                                </div>
                                <div className="ml-3 flex-1">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-base font-semibold truncate max-w-[225px] text-sm">{notification.title}</h3>
                                        <p className="text-xs text-gray-300">
                                            {(() => {
                                                let lang = i18n.language || 'nl';
                                                if (lang === 'en') {
                                                    lang = 'en-GB';
                                                }

                                                const notificationDate = new Date(notification.sentAt.date);
                                                const today = new Date();

                                                const isToday =
                                                    notificationDate.getDate() === today.getDate() &&
                                                    notificationDate.getMonth() === today.getMonth() &&
                                                    notificationDate.getFullYear() === today.getFullYear();

                                                return isToday
                                                    ? notificationDate.toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' })
                                                    : notificationDate.toLocaleDateString(lang, { month: 'short', day: 'numeric' });
                                            })()}
                                        </p>
                                    </div>
                                    <p className="text-sm text-gray-100 whitespace-pre-line word-break custom-truncate max-w-[260px] overflow-hidden">{notification.text}</p>
                                </div>
                            </div>
                        ))
                    )}

                    <style>
                        {`.custom-truncate { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }`}
                    </style>
                </div>
            </MobileApp>
        </>
    );
};

export default NotifyHistory;
