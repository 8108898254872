import { faArrowDown, faArrowUp, IconDefinition } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { animate, useAnimation, useMotionValue } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

export interface CardValueProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    value: string | number,
    net?: 'positive' | 'negative',
    icon?: IconDefinition
}

const CardValue = (props: CardValueProps) => {

    const { value, net, icon, ...restProps } = props

    const ref = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        if(!ref.current) return
        if(typeof value == 'number')
            animate(0, value, {
                duration: 1,
                onUpdate: (v: number) => { 
                    if(ref.current) ref.current.textContent = Math.round(v) + ''
                }
            })
        else ref.current.textContent = value
    }, [ value ])

    return (
        <div {...restProps} className={`flex text-6xl flex-grow items-center gap-6 float-left p-3`}>
            <div className='z-10' style={{fontSize: '50px'}}>
                <span ref={ ref }></span>
                { net == 'positive' && <FontAwesomeIcon className={`${value === 0 ? 'text-green-400/40' : 'text-green-400'} align-text-top p-2`} style={{fontSize: '0.4em', transform: 'rotate(45deg)'}} icon={ faArrowUp } /> }
                { net == 'negative' && <FontAwesomeIcon className={`${value === 0 ? 'text-red-500/40' : 'text-red-500'} align-text-top p-2`} style={{fontSize: '0.4em', transform: 'rotate(-45deg)' }} icon={ faArrowDown } /> }
            </div>
        </div>
    )
}

export default CardValue