import info from "../../Assets/info.svg";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface TooltipProps {
    text: string;
    icon?: IconProp;
    imgSmall?: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ text, icon, imgSmall }) => {

    return (
        <div className='relative items-center justify-center'>
            <div className='relative group flex items-center justify-center cursor-help'>
                {icon ? (
                    <FontAwesomeIcon icon={icon} className={`${imgSmall ? 'w-4.5 h-4.5' : 'w-6 h-6'} opacity-60`} />
                ) : (
                    <img src={info} className={`${imgSmall ? 'w-4.5 h-4.5' : 'w-6 h-6'} opacity-60`} />
                )}
                
                <div className='absolute bottom-full mb-2 hidden group-hover:flex flex-col items-center'>
                    <span className='relative z-10 p-2 text-xs text-white w-[400px] whitespace-no-wrap bg-black shadow-lg rounded-md'>
                        {text}
                    </span>
                    <div className='w-3 h-3 -mt-2 rotate-45 bg-black'></div>
                </div>
            </div>
        </div>
    );
};

export default Tooltip;
