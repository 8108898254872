import React, { useState } from "react";
import path from "../../../../../Assets/path.svg";
import { SystemFieldProps } from "../types";
import { useMapperContext } from "../Context/MapperContext";
import { useTranslation } from "react-i18next";
import info from "../../../../../Assets/info.svg";

const SystemField: React.FC<SystemFieldProps> = ({ systemField }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { isChecked, setIsChecked } = useMapperContext();
  const { t, i18n } = useTranslation();

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);

  return (
    <>
      <article className="flex flex-row justify-between items-center w-[320px] gap-[20px] h-[45px] py-[15px] mb-[15px] bg-white opacity-100">
        <div className={`flex w-[75%] flex-row`}>
          <p className={`text-[16px] ${systemField.option === 'Mandatory' && 'font-bold'} ${systemField.checkbox ? 'whitespace-nowrap' : ''}`}>
            {systemField.title}
            {systemField.option === "Mandatory" && <span className="text-red-700 text-l">*</span>}
          </p>
          {systemField.checkbox && (
            <div className={`ml-3 flex gap-1 items-center ${i18n.language === 'fr' ? 'whitespace-wrap' : 'whitespace-nowrap'}`}>
              <input type="checkbox" checked={isChecked} onChange={handleCheckBoxChange} className="cursor-pointer z-[100]" />
              <p className="text-sm">{t("mapping:setToday")}</p>
              <div className="relative flex ml-2 justify-center">
                {tooltipVisible && (
                  <div className="rounded-[0.4rem] absolute w-[250px] bottom-5 z-10 bg-map-blue text-white text-sm py-1 px-2 border shadow-lg overflow-visible">
                    <p className="text-center whitespace-normal">
                      {t("mapping:actionChangeDateAll")}
                    </p>
                  </div>
                )}
              </div>
              <img
                src={info}
                alt="info icon"
                className="cursor-pointer ml-[-10px]"
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
              />
            </div>
          )}
        </div>
        <img src={path} alt={`${systemField.title} icon`} className="opacity-0 2xl:opacity-100" />
      </article>
      <div className="h-[45px] flex 2xl:hidden pointer-events-none" />
    </>
  );
};

export default SystemField;
