import { useTranslation } from "react-i18next";
import MappedFields from "./MappedFields";
import { useMapperContext } from "../Context/MapperContext";


const MappedColumn = () => {
  const {t} = useTranslation()

  const {systemFields,fields} = useMapperContext();
  
  return (
    <section className="m-2.5">
      <h2 className="relative flex items-center opacity-0 2xl:opacity-100 my-3.5 text-[18px] font-semibold md:ml-0">{t('mapping:mappingTo')}</h2>

          {systemFields.map((systemField, index) => {
              const mappedFields = fields.filter(
              (field) => field.mappedTo.includes(systemField.value)
              );
              return <MappedFields systemField={systemField} mappedFields={mappedFields} index={index} key={index}/>
        })}

    </section>
  );
};

export default MappedColumn;
