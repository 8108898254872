import { faAdd } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from './Card/Card'
import Title from './Title'
import {useTranslation} from "react-i18next";
import { isFullWidth } from 'validator';

export interface ThemePreviewProps {
    tint: string,
    lighterTint: string,
    fileBlob: string,
    fullWidth?: boolean
}



const ThemePreview = ({ tint, lighterTint, fileBlob, fullWidth }: ThemePreviewProps) => {
    const { t } = useTranslation()
    return (
        <div className={`flex ${fullWidth ? 'w-full' : 'w-full md:w-1/2 max-w-[600px]'} flex-col cursor-not-allowed`}>
            <Title text={t('organisation:theme:preview:title')} />
            <Card rounded free className='flex flex-col flex-grow gap-6 bg-[#f6f8fa]'>
                <div className='flex flex-col relative'>
                    <div>{t('organisation:theme:preview:logo')}</div>
                    <div className='flex mt-4 gap-4 flex-wrap'>
                        <div className='flex items-center justify-center w-[100px] h-[100px] rounded-[10px] bg-light-200'>
                            { fileBlob && <img className='w-[80px] h-[80px] object-contain' src={ fileBlob } /> }
                        </div>
                        <div className='flex items-center justify-center w-[100px] h-[100px] rounded-[10px] bg-light-200 text-[#6A6A6A]'>
                            <FontAwesomeIcon className='w-[25px] h-[25px]' icon={ faAdd }/>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div>{t('organisation:theme:preview:buttons')}</div>
                    <div className='flex items-center justify-center font-medium text-[16px] p-[20px] m-[20px] rounded-[0.4rem] text-white' style={{ backgroundColor: tint }}>{t('organisation:theme:preview:standardButton')}</div>
                    <div className='flex items-center justify-center font-medium text-[16px] p-[20px] m-[20px] rounded-[0.4rem] text-white' style={{ backgroundColor: lighterTint }}>{t('organisation:theme:preview:buttonPushed')}</div>
                </div>
            </Card>
        </div>
    )
}

export default ThemePreview