import { faArrowRight, faCheck, faPlus, faQuestionCircle, faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useUploadMutation } from '../../../../Redux/Api/Api';
import {
    useAddLogoMutation, useImportCardsMutation, usePullFeedMutation,
    usePutNewsFeedMutation,
    usePutSocialsMutation,
    usePutThemeMutation,
} from '../../../../Redux/Api/Organisations';
import Card from '../../../Shared/Card/Card'
import Button from '../../../Shared/Form/Button'
import Title from '../../../Shared/Title'
import WizardLine from './WizardLine'
import { JWT } from '../../../../Utils/JWT'
import { OrganisationSettings, Social } from '../../../../Redux/Api/Request/Organisations'
import ColorPicker from '../../../Shared/Form/ColorPicker'
import ThemePreview from '../../../Shared/ThemePreview'
import FilePicker from '../../../Shared/Form/FilePicker'
import { AnimatePresence, motion } from 'framer-motion'
import Input from '../../../Shared/Form/Input'
import Dropdown from '../../../Shared/Form/Dropdown'
import { all, max, min } from '../../../../Utils/InValidation'
import Form from '../../../Shared/Form/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSVLoader } from '../../../../Services/CSVLoader'
import { ModalContext } from '../../../Shared/Modal/Modal'
import Table from '../../../Shared/Table/Table'
import Header from '../../../Shared/Table/Header'
import Column from '../../../Shared/Table/Column'
import Data from '../../../Shared/Table/Data'
import Row from '../../../Shared/Table/Row'
import BusyArea from '../../../Shared/BusyArea'
import StatusPopups from '../../../Shared/Form/StatusPopups'
import { Hydra } from '../../../../Utils/Hydra'
import { useAppDispatch, useAppSelector } from '../../../../Redux/store'
import { useNavigate } from 'react-router-dom'
import { putSettingsLocal, putThemeLocal } from '../../../../Redux/State/Organisation'
import Constants from '../../../../Constants'
import Switch from "../../../Shared/Form/Switch";
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

const WizardPage = () => {

    const organisation_name = JWT.read<string>('organisation_name')
    const org_id = useAppSelector((s) => s.organisation.id)!
    const theme = useAppSelector((s) => s.organisation.settings?.theme)

    const { t, i18n } = useTranslation()

    const steps = [
        t('wizzard:stepInformation'),
        t('wizzard:stepTheme'),
        t('wizzard:stepSocials'),
        t('wizzard:stepNews'),
        t('wizzard:stepImport')
    ];

    const modalContext = useContext(ModalContext)

    const dispatch = useAppDispatch()

    const [progress, setProgress] = useState(0)
    const [busy, setBusy] = useState(false)
    const [tint, setTint] = useState('#E6171A')
    const [lighterTint, setLighterTint] = useState('#ed4245')
    const [fileBlob, setFileBlob] = useState('')
    const [socials, setSocials] = useState<Array<Social>>([])
    const [rssInfoOpen, setRssInfoOpen] = useState(false)
    const [cardFile, setCardFile] = useState<File | null>(null)
    const [fileIsValid, setFileIsValid] = useState<string>('')
    const [csvData, setCsvData] = useState<Array<Array<string>>>()
    const [isDataOpen, setIsDataOpen] = useState(false)
    const [error, setError] = useState('')
    const [height, setHeight] = useState(0)

    const [putTheme] = usePutThemeMutation()
    const [uploadFile] = useUploadMutation()
    const [addLogo] = useAddLogoMutation()
    const [putSocials] = usePutSocialsMutation()
    const [putFeed] = usePutNewsFeedMutation()
    const [pullFeed] = usePullFeedMutation()
    const [importCards] = useImportCardsMutation()

    const ref = useRef<HTMLDivElement>(null)

    const checkHeight = (add?: number) => {
        if (!ref.current) return

        let rect = ref.current.getBoundingClientRect()

        setHeight(rect.height + (add || 0))
    }

    useEffect(() => {
        checkHeight(progress == 2 ? 50 : 0)
        setTimeout(() => checkHeight(50), 100)
        setRssInfoOpen(false)
    }, [progress])

    useEffect(() => {
        if (!theme) return

        setTint(theme.colors.tint)
        setLighterTint(theme.colors.lighterTint)

        setFileBlob(theme.icon)
    }, [theme])

    const addSocial = () => {
        setSocials((s) => [...s, {
            text: '',
            url: '',
            type: ''
        }])

        // 🤮
        setTimeout(() => checkHeight(50), 100)
        setTimeout(() => document.getElementById(`social${socials.length}`)?.scrollIntoView(), 200)

    }

    const deleteSocial = (index: number) => {
        setSocials((s) => {
            let cp = [...s]

            cp.splice(index, 1)

            return cp
        })

        setTimeout(() => checkHeight(50), 100)
    }

    useEffect(() => {
        if (!cardFile) return

        setFileIsValid('')

            ; (async () => {
                const loadedData = await CSVLoader.load(await cardFile.text(), t)

                setCsvData(loadedData.data!)
                setIsDataOpen(true)

                if (loadedData.error) setFileIsValid(loadedData.error)
                setTimeout(() => checkHeight(50), 100)
            })()

    }, [cardFile])

    useEffect(() => checkHeight(50), [fileIsValid])
    useEffect(() => void setTimeout(() => checkHeight(50), 100), [isDataOpen])

    useEffect(() => {
        dispatch(putThemeLocal({
            background: '',
            normal: '',
            border: '',
            inactive: '',
            text: '',
            lighterText: '',
            tint: tint,
            lighterTint: lighterTint
        }))
    }, [tint, lighterTint])

    const finish = async (data: {
        feed: string,
        cards?: File,
        send: boolean,
        tint: string,
        lighterTint: string,
        logo?: File
    }) => {
        if (busy) return

        if (fileIsValid && !(await modalContext.show(
            t("wizzard:error1"),
            t("wizzard:error2") + fileIsValid
        ))) {
            modalContext.hide();
            return;
        }


        modalContext.hide()

        setBusy(true)

        let formattedData: Array<Social> = []

        for (let [k, v] of Object.entries(data)) {
            if (!k.startsWith('social_')) continue
            let [_, index, name] = k.split('_')

            if (!formattedData[parseInt(index)]) {
                (formattedData[parseInt(index)] as any) = {}
            }

            (formattedData[parseInt(index)] as any)[name] = v
        }

        const settings: OrganisationSettings = {
            theme: {
                colors: {
                    background: '',
                    normal: '',
                    border: '',
                    inactive: '',
                    text: '',
                    lighterText: '',
                    tint: data.tint,
                    lighterTint: data.lighterTint
                },
                icon: ''
            },
            socials: formattedData,
            news: {
                feed: data.feed
            },
            organisationLanguage: 'NL',
            organisationEmail: 'suport@digipas.app',
            hidden: false,
            LastReminderEmail: null
        }

        try {
            await putTheme({
                id: org_id,
                settings: settings.theme.colors
            }).unwrap()

            if (data.logo) {
                const upload_id = await uploadFile({
                    file: data.logo
                }).unwrap()

                await addLogo({
                    organisation_id: org_id,
                    upload_id
                })
            }

            if (socials.length > 0) {
                await putSocials({
                    id: org_id,
                    socials: settings.socials
                }).unwrap()
            }

            if (settings.news.feed) {
                await putFeed({
                    id: org_id,
                    feed: settings.news.feed
                }).unwrap()

                try {
                    await pullFeed(org_id).unwrap()
                } catch (e) { }
            }

            if (data.cards) {
                const file_id = await uploadFile({
                    file: data.cards
                }).unwrap()

                const formatDateString = (dateString: string): string => {
                    return dateString.replace(/\s*\(.*?\)\d{2}:\d{2}/, '');
                };
                const selectedDateTime = formatDateString(new Date().toDateString())

                // @ts-ignore
                await importCards([org_id, file_id, data.send, selectedDateTime]).unwrap()
            }

            dispatch(putSettingsLocal(settings))

            window.location.pathname = `/${i18n.language}/dashboard/success`
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }
    }

    const finishSkip = async () => {
        if (busy) return

        setBusy(true)

        try {
            await putTheme({
                id: org_id,
                settings: theme!.colors!
            }).unwrap()

            window.location.pathname = `/${i18n.language}/dashboard`
        } catch (e) {
            let err = e as Hydra.Error
            setError(err['hydra:description'])
            setBusy(false)
        }
    }

    return (
        <Form className='flex flex-grow min-h-full items-center justify-center' nostyle submit={finish}>
            <Card rounded free className='flex flex-col w-full 2xl:w-7/12 my-20'>
                <BusyArea busy={busy}>
                    <Title text={t("wizzard:headText")} subtitle={t("wizzard:headTextPrefix") || " "} />
                    <WizardLine current={progress} items={steps} />
                    <motion.div transition={{ ease: [0.25, 1, 0.5, 1] }} animate={{ height }} className='flex flex-col relative'>
                        <div ref={ref} className='absolute w-full flex flex-col my-4 gap-4 min-h-[20rem]'>
                            <div className={`${progress !== 0 && 'hidden'} flex flex-col`}>
                                <Trans i18nKey="wizzard:info" values={{ organisation_name: organisation_name }} components={{ br: <br /> }} />

                            </div>
                            <div className={`${progress !== 1 && 'hidden'} flex flex-col`}>
                                <div className='flex flex-col'>
                                    <Trans i18nKey="wizzard:infoTheme" components={{ br: <br /> }} />
                                </div>
                                <ColorPicker initial='#E6171A' color={tint} onChangeReactive={setTint} id='tint' label={t("wizzard:primaryColor")} />
                                <ColorPicker initial='#ed4245' color={lighterTint} onChangeReactive={setLighterTint} id='lighterTint' label={t("wizzard:secondaryColor")} />
                                <FilePicker id='logo' label='Logo' onChangeBlob={setFileBlob} accept={['image/*']} />
                                <ThemePreview fullWidth tint={tint} lighterTint={lighterTint} fileBlob={fileBlob} />
                            </div>
                            <div className={`${progress !== 2 && 'hidden'} flex flex-col`}>
                                <div className='flex flex-col'>
                                    {t("wizzard:infoSocials")}
                                </div>
                                <div className='flex'>
                                    <Button nosubmit action={addSocial} icon={faPlus} iconright title={t("wizzard:addSocials")} />
                                </div>
                                <div className='flex flex-col relative gap-4 mt-4'>
                                    <AnimatePresence>
                                        {socials.map((s, i) => (
                                            <motion.div
                                                key={JSON.stringify(s)}
                                                animate={{ opacity: 1, scale: 1 }}
                                                exit={{ opacity: 0, scale: 1.1, height: 0 }}
                                                id={`social${i}`}
                                                className='flex flex-col flex-grow w-full'>
                                                <Card rounded outline free>
                                                    <Input placeholder={t("wizzard:suggestionName") || " "} id={`social_${i}_text`} initial={s.text} required invalidator={all(min(3), max(30))} label={t("wizzard:name")} sublabel={'Social ' + (i + 1)} />
                                                    <Input type='url' placeholder={t("wizzard:suggestionURL") || " "} id={`social_${i}_url`} initial={s.url} required invalidator={min(3)} label='URL' />
                                                    <Dropdown id={`social_${i}_type`} initial={s.type} options={{ facebook: 'Facebook', twitter: 'X', other: 'Overig' }} label={t("wizzard:type")} />
                                                    <div className='flex'>
                                                        <Button secondary nosubmit action={() => deleteSocial(i)} title={t("wizzard:delete")} icon={faTrashAlt} />
                                                    </div>
                                                </Card>
                                            </motion.div>
                                        ))}
                                    </AnimatePresence>
                                </div>
                            </div>
                            <div className={`${progress !== 3 && 'hidden'} flex flex-col gap-2`}>
                                <div className='flex flex-col'>
                                    {t("wizzard:infoNews")}
                                    <div>
                                        {t("wizzard:infoRSSFeed")}
                                        <FontAwesomeIcon onClick={() => setRssInfoOpen(s => !s)} className='cursor-pointer opacity-60 ml-1' icon={faQuestionCircle} />
                                    </div>
                                    <motion.div
                                        animate={{ scale: rssInfoOpen ? 1 : 0, opacity: rssInfoOpen ? 1 : 0 }}
                                        className='origin-top-left bg-light-200 p-4 mt-2'>{t("wizzard:infoRSSFeedExpanded1")} <a target='_blank' className='text-accent hover:text-accent-light' href='https://x-interactive.nl/contact/'>{t("wizzard:infoRSSFeedExpanded2")}</a>.</motion.div>
                                </div>
                                <Input type='url' id='feed' label='RSS Feed URL' />
                            </div>
                            <div className={`${progress !== 4 && 'hidden'} flex flex-col`}>
                                <StatusPopups type='success' subtitle={t("cards:import:error") as string} text={fileIsValid} />
                                <StatusPopups type='error' text={error} />
                                <div className='flex flex-col'>
                                    {t("wizzard:infoImport")}
                                </div>
                                <a className='my-2' href={Constants.DOWNLOAD_URL_CARDS_TEMPLATE} download><Button secondary nosubmit title={t("wizzard:downloadTemplate")} /></a>
                                <FilePicker onChange={setCardFile} accept={['text/csv']} label={t("wizzard:CSVFile")} id='cards' />
                                {csvData && (
                                    <>
                                        <Button
                                            title={isDataOpen ? t('cards:import:hideExample') : t('cards:import:watchExample')}
                                            className='mb-6'
                                            nosubmit
                                            action={() => setIsDataOpen(s => !s)} />
                                        <motion.div
                                            initial={{ maxHeight: '0vh' }}
                                            animate={{ maxHeight: isDataOpen ? '50vh' : '0vh' }}
                                            className='flex flex-col overflow-hidden w-full'>
                                            <Table itemsPerPage={50}>
                                                <Header>
                                                    <Column name={t('cards:list:line')} />
                                                    <Column name={t('cards:list:initials')} />
                                                    <Column name={t('cards:list:lastname')} />
                                                    <Column name={t('cards:list:email')} />
                                                    <Column name={t('cards:list:membershipId')} />
                                                    <Column name={t('cards:list:activeFrom')} />
                                                    <Column name={t('cards:list:activeUntil')} />
                                                    <Column name={t('cards:list:type')} />
                                                    <Column name={t('cards:list:dateOfBirth')} />
                                                    <Column name={t('cards:list:location')} />
                                                    <Column name={t('cards:list:phone')} />
                                                    <Column name={t('cards:list:department')} />
                                                    <Column name={t('cards:list:countryCode')} />
                                                    <Column name={t('cards:list:discount')} />
                                                    <Column name={t('cards:list:team')} />
                                                </Header>
                                                <Data>
                                                    {csvData.map((row, index) => (
                                                        <Row
                                                            firstButton=''
                                                            lastButton=''
                                                            errorAware
                                                            key={index}
                                                            index={index}
                                                            data={row} />
                                                    ))}
                                                </Data>
                                            </Table>
                                        </motion.div>
                                    </>
                                )}
                                <Switch id='send' label={t('cards:list:sendMail?')} />
                            </div>
                        </div>
                    </motion.div>
                    <div className='flex justify-between'>
                        <Button disabled={progress == 0} action={() => setProgress((s) => Math.max(s - 1, 0))} secondary nosubmit title={t("wizzard:back")} icon={faArrowLeft} />
                        {progress !== steps.length - 1 && <Button action={() => setProgress((s) => Math.min(s + 1, steps.length - 1))} nosubmit title={t("wizzard:next")} icon={faArrowRight} iconright />}
                        {progress === steps.length - 1 && <Button title={t("wizzard:finnish")} icon={faCheck} iconright />}
                    </div>
                </BusyArea>
                <div onClick={modalContext.withModal({ title: t("wizzard:skipAllText1"), body: t("wizzard:skipAllText2") }, finishSkip)} className='absolute right-0 -bottom-10 px-6 cursor-pointer hover:underline'><Trans i18nKey="wizzard:skipAll" components={{ b: <b /> }} /></div>
            </Card>
        </Form>
    )
}

export default WizardPage