import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type RowAction = {
    icon?: ReactNode,
    label: string,
    onClick: () => void,
};

type RowActionsProps = {
    actions: Array<RowAction>,
    small?: boolean,
};

export default function RowActions({ actions, small }: RowActionsProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                style={{ border: '1px solid #D7E3F2' }}
                className={`text-black py-1 overflow-hidden rounded-[0.4rem] ${open ? 'bg-gray-300' : ''}`}
                onClick={handleClick}
                sx={{
                    ml: 0,
                    mr: 0,
                    ...(open && {
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateX(-50%) translateY(-50%) rotate(45deg)',
                            width: 10,
                            height: 10,
                            bgcolor: 'white',
                            border: '1px solid #D7E3F2',
                        },
                    }),
                }}

            >


                <div className={`${small ? '' : 'px-6'} py-1 text-black`}>
                    {!small ? t('datagrid:actions') : null}
                    <FontAwesomeIcon className={!small ? 'pl-2' : ''} icon={faEllipsis} />
                </div>
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        borderRadius: '0.4rem',
                        border: '1px solid #D7E3F2',
                        zIndex: 10,
                    },
                }}

            >
                {actions.map(action => (
                    <MenuItem key={action.label} onClick={() => action.onClick()} className="h-8 px-5 my-1">
                        <div className="flex items-center w-full border-b-[1px] border-[#dbdbdb]">
                            {action.icon && (
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                    {action.icon}
                                </ListItemIcon>
                            )}
                            <ListItemText className="py-1 px-1">
                                {action.label}
                            </ListItemText>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
