import Title from "../../../Shared/Title";
import StatusPopups from "../../../Shared/Form/StatusPopups";
import React, { useState } from "react";
import { useUploadMutation } from "../../../../Redux/Api/Api";
import { usePostSponsorMutation } from "../../../../Redux/Api/Sponsors";
import { useAddSponsorLogoMutation } from "../../../../Redux/Api/Sponsors";
import FilePicker from "../../../Shared/Form/FilePicker";
import Button from "../../../Shared/Form/Button";
import BusyArea from '../../../Shared/BusyArea';
import Form from '../../../Shared/Form/Form';
import { all, max, min } from "../../../../Utils/InValidation";
import Input from "../../../Shared/Form/Input";
import CalendarInput from "../../../Shared/Form/CalendarInput";
import { useAppSelector } from "../../../../Redux/store";
import { Hydra } from "../../../../Utils/Hydra";
import { SponsorResponse } from "../../../../Redux/Api/Request/Sponsors";
import { useAddNewsLogoMutation, usePostNewsMutation } from "../../../../Redux/Api/News";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css'
import { faFloppyDisk, faPlus } from "@fortawesome/pro-solid-svg-icons";
import MobileApp from "../../../Shared/App/MobileApp";
import News from "../../../Shared/App/News";

const AddNewsPage = () => {

    const org_id = useAppSelector((s) => s.organisation.id)!;
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [uploadFile] = useUploadMutation();
    const [postNews] = usePostNewsMutation();
    const [addNewsLogo] = useAddNewsLogoMutation();

    const [busy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fileBlob, setFileBlob] = useState('');
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');

    const submit = async (data: {
        title: string,
        image: File,
        url: string
    }) => {
        if (busy) return;
        setBusy(true);
        setError('');
        setSuccessMessage('');

        if (text.length > 49000) {
            setError(t('organisation:news:tooLong') as string);
            setBusy(false);
            return;
        }

        try {
            let upload_id;
            if (data.image) {
                upload_id = await uploadFile({
                    file: data.image
                }).unwrap();
            }


            const outData = {
                title: data.title,
                text: text,
                organisation: org_id,
                url: data.url
            }

            const news_id = await postNews([org_id, outData]).unwrap();

            if (upload_id) {
                await addNewsLogo({
                    news_id: news_id.toString(),
                    upload_id
                });
            }

            sessionStorage.setItem('successMessage', t('organisation:news:successAdd') as string);
            setBusy(false)
            navigate(`/${i18n.language}/dashboard/news/list`);
        } catch (e) {
            setError(t('modal:error') as string);
            setBusy(false);
        }
    }

    return (
        <div>
            <BusyArea busy={busy}>
                <div className='flex flex-col xl:flex-row'>
                    <div className='w-full'>
                        <Title text={t('organisation:news:title')} subtitle={t('organisation:news:addNews') as string} textNotBold />
                        <StatusPopups type='error' text={error} />
                        <StatusPopups type='success' text={successMessage} />
                        <Form submit={submit}>
                            <Input
                                required
                                submitted={error ? true : false}
                                invalidator={all(min(2), max(255))}
                                label={t('organisation:news:tableTitle')}
                                onChange={(e) => { setTitle((e.target as HTMLInputElement).value); }}
                                id='title' />
                            <FilePicker required submitted={error ? true : false} id='image' label={t('organisation:news:image')} onChangeBlob={setFileBlob} accept={['image/*']} />
                            <Input
                                sublabel={t('organisation:news:urlOptional') as string}
                                invalidator={all(min(0), max(255))}
                                label={t('organisation:news:url')}
                                onChange={(e) => { setUrl((e.target as HTMLInputElement).value); }}
                                placeholder="https://"
                                type='url'
                                id='url' />
                            <label>{t('organisation:news:text')} <span className='opacity-70 text-sm'>({t('cards:list:optional')})</span></label>
                            <ReactQuill
                                value={text}
                                onChange={setText}
                                id='text'
                                className={'mb-14 bg-transparent border-black border-opacity-10 h-80 max-w-[calc(100vw-400px) lg:max-w-[calc(100vw-400px)]'}
                            />
                            <div className='flex mt-20'>
                                <Button icon={faPlus} iconright title={t('organisation:news:add')} />
                            </div>
                        </Form>
                    </div>


                    <div className='-m-32 sm:m-0 min-w-0 xl:min-w-[460px] scale-75 sm:scale-100 overflow-hidden flex justify-center xl:justify-end mb-0 sm:mb-10'>
                        <MobileApp activeItem="news">
                            <News
                                customNews
                                customTitle={title}
                                customImg={fileBlob}
                                customText={text}
                                customLink={url}
                            />
                        </MobileApp>
                    </div>
                </div>
            </BusyArea>
        </div>
    )
}

export default AddNewsPage
